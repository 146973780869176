import { ElementViewModel } from '@hl7fhir/viewmodels';
import { Extension, Reference } from '@hl7fhir';
import { ExtensionPipe, ExtensionsPipe } from '@hl7fhir/extensibility';
import { getReference } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { SelectPipe } from '@shared';
import * as r3 from 'fhir/r3';

export class CoveragePayorViewModel extends ElementViewModel<Reference> {
  get name(): string | undefined {
    return getReference(this.element);
  }

  get bankName(): string | undefined {
    const bankInformationExtensions: Extension[] | undefined = new ExtensionsPipe().transform(
      this.element?.extension,
      StructureDefinition.Nictiz.PAYER.bankInformation,
    );
    const bankNameExtension: Extension | undefined = new ExtensionPipe().transform(
      bankInformationExtensions?.flatMap((extension) => extension.extension as r3.Extension[]),
      StructureDefinition.Nictiz.BANK_INFORMATION.bankName,
    );
    const valueString: string | undefined = new SelectPipe().transform(bankNameExtension, 'valueString');

    return valueString;
  }

  get bankCode(): string | undefined {
    const bankInformationExtensions: Extension[] | undefined = new ExtensionsPipe().transform(
      this.element?.extension,
      StructureDefinition.Nictiz.PAYER.bankInformation,
    );
    const bankCodeExtension: Extension | undefined = new ExtensionPipe().transform(
      bankInformationExtensions?.flatMap((extension) => extension.extension as r3.Extension[]),
      StructureDefinition.Nictiz.BANK_INFORMATION.bankCode,
    );
    const valueString: string | undefined = new SelectPipe().transform(bankCodeExtension, 'valueString');

    return valueString;
  }

  get accountNumber(): string | undefined {
    const bankInformationExtensions: Extension[] | undefined = new ExtensionsPipe().transform(
      this.element?.extension,
      StructureDefinition.Nictiz.PAYER.bankInformation,
    );
    const accountNumberExtension: Extension | undefined = new ExtensionPipe().transform(
      bankInformationExtensions?.flatMap((extension) => extension.extension as r3.Extension[]),
      StructureDefinition.Nictiz.BANK_INFORMATION.accountNumber,
    );
    const valueString: string | undefined = new SelectPipe().transform(accountNumberExtension, 'valueString');

    return valueString;
  }
}
