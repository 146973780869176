import { TranslationEntry } from '@globals';
import { Coding } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { AvailableLocales, toLocale } from '@language';

export interface ValueSetFlattened {
  id?: string;
  systems?: Record<string, Record<string, TranslationEntry>>;
}

export interface ValueSetDisplay {
  /**
   * The label of the value set.
   */
  system: string | undefined;
  /**
   * The display value for the code.
   */
  code: string | undefined;
}

export class ValueSets {
  /**
   * Returns the name of the values set for the given system
   * and the display value of the code if the code is present in the coding.
   * @param coding The coding the lookup
   * @returns a composite of the system name and the code's display value
   */
  static getDisplay(coding: Coding, valueSets: ValueSetFlattened[]): ValueSetDisplay {
    const result: ValueSetDisplay = { system: undefined, code: undefined };
    if (!coding || !coding.system) {
      return result;
    }

    result.system = CodeSystems.getLabel(coding.system);

    if (coding.code) {
      // Try and get display for code from the given value sets
      for (const valueSet of valueSets) {
        const entry: TranslationEntry | string | undefined = valueSet.systems?.[coding.system]?.[coding.code];
        if (entry !== undefined) {
          const preferredLanguage: AvailableLocales = toLocale($localize.locale);
          // Select the preferred locale otherwise fallback to en-US if available in the values set and then to nl.
          result.code = entry[preferredLanguage] ?? entry['en-US'] ?? entry.display;
          break;
        }
      }

      // If we don't have a display for the code use the code systems as a fallback
      if (!result.code) {
        result.code = CodeSystems.getDisplay(coding);
      }
    }

    return result;
  }
}
