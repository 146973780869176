import { ErrorHandlerService } from '@core/services';
import { AccountSyncStatusEntryPartial, CAFileListResponse, Status } from '@digi.me/models';
import { createSelector } from '@ngrx/store';
import { DigiMeState } from '../digi-me.reducer';
import { selectFilesLoaded } from './digi-me-files.selectors';
import { selectDigiMeState } from './digi-me.selectors';

export const selectFileList = createSelector(selectDigiMeState, (state: DigiMeState) => state?.fileList.data);

export const selectFileListStatus = createSelector(selectFileList, (state: CAFileListResponse | null) => state?.status);

export const selectShouldReauthorize = createSelector(selectFileListStatus, (state: Status | undefined) => {
  const details = state?.details;
  const accountId = ErrorHandlerService.extractAccountId(details);

  if (accountId && details?.[accountId] && 'error' in details[accountId]) {
    const syncStatus = details[accountId] as AccountSyncStatusEntryPartial;
    return syncStatus.error?.reauth ?? false;
  }

  return false;
});

export const selectBinaryFiles = createSelector(selectFileList, (fileList) =>
  fileList?.fileList?.filter((file) => file.name.includes('_502_')),
);

export const selectAllBinariesLoaded = createSelector(
  selectBinaryFiles,
  selectFilesLoaded,
  (binaryFiles, filesLoaded) => binaryFiles?.every((file) => filesLoaded.includes(file.name)) ?? true,
);
