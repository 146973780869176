import { convertPeriodToSortable, convertToSortable } from '@globals';
import { CodeableConcept, Extension, Procedure } from '@hl7fhir';
import { AnnotationViewModel, CodeableConceptPipe, IdentifierViewModel, getChoiceOfType } from '@hl7fhir/data-types';
import { ExtensionPipe, ExtensionsPipe } from '@hl7fhir/extensibility';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { ProcedureStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { SelectsPipe } from '@shared';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { ProcedureFocalDeviceViewModel } from './procedure-focal-device.viewmodel';
import { ProcedurePerformerViewModel } from './procedure-performer.viewmodel';

export class ProcedureViewModel extends DomainResourceViewModel<Procedure> {
  get category(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.category);
  }

  get bodySite(): string | undefined {
    const procedure = this.resource as r3.Procedure | r4.Procedure | r4b.Procedure | undefined;
    return new CodeableConceptPipe().transform(procedure?.bodySite);
  }

  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.code);
  }

  get subject(): string | undefined {
    return getReference(this.resource?.subject);
  }

  get performed(): string | undefined {
    const procedure = this.resource as r3.Procedure | r4.Procedure | r4b.Procedure | undefined;
    return getChoiceOfType({
      string: procedure?.performedDateTime,
      period: procedure?.performedPeriod,
    });
  }

  get performedSortable(): Date | undefined {
    const procedure = this.resource as r3.Procedure | r4.Procedure | r4b.Procedure | undefined;
    return convertToSortable(procedure?.performedDateTime) ?? convertPeriodToSortable(procedure?.performedPeriod);
  }

  get status(): string | undefined {
    return new ProcedureStatusPipe().transform(this.resource?.status);
  }

  get outcome(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.outcome);
  }

  get complication(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.complication);
  }

  get basedOn(): string | undefined {
    return getReferences(this.resource?.basedOn);
  }

  get complicationDetail(): string | undefined {
    const procedure = this.resource as r3.Procedure | r4.Procedure | r4b.Procedure | undefined;
    return getReferences(procedure?.complicationDetail);
  }

  get followUp(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.followUp);
  }

  get location(): string | undefined {
    return getReference(this.resource?.location);
  }

  get partOf(): string | undefined {
    return getReferences(this.resource?.partOf);
  }

  get reasonCode(): string | undefined {
    const procedure = this.resource as r3.Procedure | r4.Procedure | r4b.Procedure | undefined;
    return new CodeableConceptPipe().transform(procedure?.reasonCode);
  }

  get reasonReference(): string | undefined {
    const procedure = this.resource as r3.Procedure | r4.Procedure | r4b.Procedure | undefined;
    return getReferences(procedure?.reasonReference);
  }

  get report(): string | undefined {
    return getReferences(this.resource?.report);
  }

  get usedCode(): string | undefined {
    const procedure = this.resource as r3.Procedure | r4.Procedure | r4b.Procedure | undefined;
    return new CodeableConceptPipe().transform(procedure?.usedCode);
  }

  get usedReference(): string | undefined {
    const procedure = this.resource as r3.Procedure | r4.Procedure | r4b.Procedure | undefined;
    return getReferences(procedure?.usedReference);
  }

  get method(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Hl7.PROCEDURE.method,
    );

    return new CodeableConceptPipe().transform(extension?.valueCodeableConcept);
  }

  get laterality(): string | undefined {
    const extensions: Extension[] | undefined = new ExtensionsPipe().transform(
      this.resource?.bodySite
        ?.map((item) => item.extension)
        .flat()
        .filter((extension): extension is Extension => !!extension),
      StructureDefinition.Nictiz.BODY_SITE.qualifier,
    );

    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectsPipe().transform(
      extensions,
      'valueCodeableConcept',
    );

    return new CodeableConceptPipe().transform(valueCodeableConcepts);
  }

  get identifier(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((item) => new IdentifierViewModel(item, this.fhirVersion));
  }

  get note(): AnnotationViewModel[] | undefined {
    return this.resource?.note?.map((item) => new AnnotationViewModel(item, this.fhirVersion));
  }

  get performer(): ProcedurePerformerViewModel[] | undefined {
    return this.resource?.performer?.map((item) => new ProcedurePerformerViewModel(item, this.fhirVersion));
  }

  get focalDevice(): ProcedureFocalDeviceViewModel[] | undefined {
    return this.resource?.focalDevice?.map((item) => new ProcedureFocalDeviceViewModel(item, this.fhirVersion));
  }
}
