import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const AllergieStatusCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.8.2.3--20171231000000',
  systems: {
    'http://hl7.org/fhir/v3/ActStatus': {
      active: {
        'nl-NL': 'Actief',
        display: 'Active',
      },
      completed: {
        'nl-NL': 'Niet meer aanwezig',
        display: 'Completed',
      },
      obsolete: {
        'nl-NL': 'Achterhaald',
        display: 'Obsolete',
      },
      nullified: {
        'nl-NL': 'Foutief',
        display: 'Nullified',
      },
    },
  },
};
