import { DetailsItem, ElementItem } from '@resource-view/models';
import { IdentifierUtils } from './identifier.utils';
import { IdentifierViewModel } from './identifier.viewmodel';

export function buildIdentifiersDetails(
  vms: IdentifierViewModel[] | IdentifierViewModel | undefined,
): DetailsItem[] | undefined {
  const identifiers = Array.isArray(vms) ? vms : vms && [vms];

  return identifiers?.map((vm: IdentifierViewModel, index: number) => {
    const systemLabel = IdentifierUtils.getSystemLabel(vm.system);

    return {
      id: `identifier-${index}`,
      header:
        systemLabel && vm.value ? `${systemLabel}: ${vm.value}` : `${$localize`:@@app.Identifier.header:Identifier`}`,
      elements: buildIdentifierElements(vm),
    };
  });
}
export function buildIdentifierElements(vm: IdentifierViewModel | undefined): ElementItem[] {
  return [
    {
      id: 'use',
      header: $localize`:@@fhir.Identifier.use:Use`,
      value: vm?.use,
    },
    {
      id: 'type',
      header: $localize`:@@fhir.Identifier.type:Type`,
      value: vm?.type,
    },
    {
      id: 'system',
      header: $localize`:@@fhir.Identifier.system:System`,
      value: vm?.system,
    },
    {
      id: 'value',
      header: $localize`:@@fhir.Identifier.value:Value`,
      value: vm?.value,
    },
    {
      id: 'period',
      header: $localize`:@@fhir.Identifier.period:Period`,
      value: vm?.period,
    },
    {
      id: 'assigner',
      header: $localize`:@@fhir.Identifier.assigner:Assigner`,
      value: vm?.assigner,
    },
  ];
}
