import { ElementViewModel } from '@hl7fhir/viewmodels';
import { Address, CodeableConcept, Extension } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { AdresSoortCodelijst } from '@hl7nl-fhir/value-sets';
import { SelectPipe } from '@shared';
import { CodePipe } from '../code';
import { CodeableConceptPipe } from '../codeable-concept';
import { LinePipe } from './pipes/address-line.pipe';
import { CountryCodeToNamePipe } from './pipes/country-code-to-name.pipe';

export class AddressViewModel extends ElementViewModel<Address> {
  get use(): string | undefined {
    return new CodePipe().transform(this.element?.use, CodeSystems.ADDRESS_USE);
  }

  get lines(): string | undefined {
    return this.element?.line?.join(',');
  }

  get postalCode(): string | undefined {
    return this.element?.postalCode;
  }

  get city(): string | undefined {
    return this.element?.city;
  }

  get district(): string | undefined {
    return this.element?.district;
  }

  get state(): string | undefined {
    return this.element?.state;
  }

  get country(): string | undefined {
    return new CountryCodeToNamePipe().transform(this.element?.country);
  }

  get street(): string | undefined {
    return new LinePipe().transform(this.element, 'iso21090-ADXP-streetName');
  }

  get houseNumber(): string | undefined {
    return new LinePipe().transform(this.element, 'iso21090-ADXP-houseNumber');
  }

  get type(): string | undefined {
    return new CodePipe().transform(this.element?.type, CodeSystems.ADDRESS_TYPE);
  }

  get kind(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.element?.extension,
      StructureDefinition.Nictiz.ADDRESS_INFORMATION.addressType,
    );
    const codeableConcept: CodeableConcept | undefined = new SelectPipe().transform(extension, 'valueCodeableConcept');

    return new CodeableConceptPipe().transform(codeableConcept, [AdresSoortCodelijst]);
  }
}
