import { HumanName } from '@hl7fhir';
import { PeriodComparer } from './period.comparer';

export class HumanNameComparer {
  static rankUse(nameUse: ('usual' | 'official' | 'temp' | 'nickname' | 'anonymous' | 'old' | 'maiden') | undefined) {
    switch (nameUse) {
      case 'usual':
        return 1;
      case 'official':
        return 2;
      case 'nickname':
        return 3;
      case 'maiden':
        return 4;
      case 'anonymous':
        return 5;
      case 'temp':
        return 6;
      case 'old':
        return 7;
      default:
        return 8;
    }
  }

  static compareUse(
    a: ('usual' | 'official' | 'temp' | 'nickname' | 'anonymous' | 'old' | 'maiden') | undefined,
    b: ('usual' | 'official' | 'temp' | 'nickname' | 'anonymous' | 'old' | 'maiden') | undefined,
  ): number {
    return this.rankUse(a) - this.rankUse(b);
  }

  static compare(a: HumanName | undefined | null, b: HumanName | undefined | null): number {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;

    // Compare periods, no period means active and does no period means higher ranked or do we first need to sort of use? TODO: Ask Hanne.
    const periodRank = PeriodComparer.compare(a.period, b.period);
    if (periodRank) return periodRank;

    return this.compareUse(a.use, b.use);
  }
}
