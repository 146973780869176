import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { selectAppState } from './app.selector';

export const selectErrorMessage = createSelector(selectAppState, (state: AppState) => {
  return state?.error;
});

export const selectShowSecurityPromptModal = createSelector(selectAppState, (state: AppState) => {
  return state?.securityPromptModal;
});

export const selectShowingErrorModal = createSelector(selectAppState, (state: AppState) => {
  return state?.showingErrorModal;
});
