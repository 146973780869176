import { Dosage } from '@hl7fhir';
import { CodeableConceptPipe, QuantityTypePipe, getChoiceOfType } from '@hl7fhir/data-types';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';

export class DosageViewModel extends ElementViewModel<Dosage> {
  get sequence(): string | undefined {
    if (!this.element?.sequence) {
      return undefined;
    }
    return this.element.sequence.toString();
  }

  get text(): string | undefined {
    return this.element?.text && this.element?.text;
  }

  get additionalInstruction(): string | undefined {
    return (
      this.element?.additionalInstruction && new CodeableConceptPipe().transform(this.element?.additionalInstruction)
    );
  }

  get patientInstruction(): string | undefined {
    return this.element?.patientInstruction;
  }

  get asNeeded(): string | undefined {
    const elementR3 = this.element as r3.Dosage;
    return getChoiceOfType({
      codeableConcept: elementR3.asNeededCodeableConcept,
      boolean: elementR3.asNeededBoolean,
    });
  }

  get site(): string | undefined {
    return this.element?.site && new CodeableConceptPipe().transform(this.element?.site);
  }

  get route(): string | undefined {
    return this.element?.route && new CodeableConceptPipe().transform(this.element?.route);
  }

  get method(): string | undefined {
    return this.element?.method && new CodeableConceptPipe().transform(this.element?.method);
  }

  get dose(): string | undefined {
    const elementR3 = this.element as r3.Dosage;
    return getChoiceOfType({
      range: elementR3.doseRange,
      quantity: elementR3.doseQuantity,
    });
  }

  get maxDosePerAdministration(): string | undefined {
    return (
      this.element?.maxDosePerAdministration && new QuantityTypePipe().transform(this.element?.maxDosePerAdministration)
    );
  }

  get maxDosePerLifetime(): string | undefined {
    return this.element?.maxDosePerLifetime && new QuantityTypePipe().transform(this.element?.maxDosePerLifetime);
  }

  get rate(): string | undefined {
    const elementR3 = this.element as r3.Dosage;
    return getChoiceOfType({
      range: elementR3.rateRange,
      quantity: elementR3.rateQuantity,
      ratio: elementR3.rateRatio,
    });
  }

  get summary(): string | undefined {
    return `${this.text ?? ''} ${this.additionalInstruction ?? ''}`.trim();
  }
}
