import { Pipe, PipeTransform } from '@angular/core';
import { HumanName } from '@hl7fhir';
import { HumanNameRankPipe } from './human-name-rank.pipe';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(
    humanNames: HumanName | HumanName[] | undefined,
    use?: 'usual' | 'official' | 'temp' | 'nickname' | 'anonymous' | 'old' | 'maiden' | undefined,
  ): string | undefined {
    if (!humanNames) {
      return undefined;
    }

    const namesArray: HumanName[] = Array.isArray(humanNames) ? humanNames : [humanNames];

    // Get list of human names by use
    const filteredNames = use ? namesArray.filter((value: HumanName) => value.use === use) : namesArray;

    if (filteredNames.length <= 0) {
      return undefined;
    }

    // Get human name by rank
    const humanNameRankPipe: HumanNameRankPipe = new HumanNameRankPipe();
    const first = humanNameRankPipe.transform(filteredNames);

    if (!first) {
      return undefined;
    }

    // Show the text representation of the full name if available
    if (first.text) {
      return first.text;
    }

    const prefix = first.prefix ? `${first.prefix} ` : '';
    const givenNames = first.given ? first.given?.join(' ') : '';
    const familyName = first.family ? ` ${first.family}` : '';
    const suffix = first.suffix ? ` ${first.suffix}` : '';

    const fullName = `${prefix}${givenNames}${familyName}${suffix}`;

    return fullName;
  }
}
