import { DocumentReference } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectDocumentReferenceId(documentReference: DocumentReference): string {
  if (!documentReference.id) throw new Error('The DocumentReference is not created yet.');

  return documentReference.id;
}

export type State = EntityState<DocumentReference>;

export const adapter: EntityAdapter<DocumentReference> = createEntityAdapter<DocumentReference>({
  selectId: selectDocumentReferenceId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { documentReferences }) => {
    return adapter.upsertMany(documentReferences, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDocumentReferenceEntities = selectEntities;

export const selectAllDocumentReferences = selectAll;
