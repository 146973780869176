import { DocumentReference } from '@hl7fhir';
import { DocumentReferenceViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromDocumentReference from './document-reference.reduce';

export const selectDocumentReferenceState = createFeatureSelector<fromDocumentReference.State>(
  features.FHIR.documentReference,
);

export const selectDocumentReferenceEntities = createSelector(
  selectDocumentReferenceState,
  fromDocumentReference.selectDocumentReferenceEntities,
);

export const selectAllDocumentReferences = createSelector(
  selectDocumentReferenceState,
  fromDocumentReference.selectAllDocumentReferences,
);

export const selectDocumentReferenceById = createSelector(
  selectRouteParams,
  selectDocumentReferenceEntities,
  ({ id }, documentReferences: Dictionary<DocumentReference>) =>
    id && (documentReferences[id] ?? documentReferences[id.toLowerCase()] ?? documentReferences[id.toUpperCase()]),
);

export const selectAllDocumentReferenceViewModels = createSelector(
  selectAllDocumentReferences,
  (documentReferences: DocumentReference[]) =>
    documentReferences.map((documentReference) => new DocumentReferenceViewModel(documentReference)),
);

export const selectDocumentReferenceViewModelById = createSelector(
  selectDocumentReferenceById,
  (entity: DocumentReference | undefined) => entity && new DocumentReferenceViewModel(entity),
);
