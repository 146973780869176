import { PatientContact } from '@hl7fhir';
import { PatientContactViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromPatientContact from './patient-contact.reducer';

export const selectPatientContactState = createFeatureSelector<fromPatientContact.State>(features.FHIR.patientContacts);

export const selectPatientContactIds = createSelector(
  selectPatientContactState,
  fromPatientContact.selectPatientContactIds,
);

export const selectPatientContactEntities = createSelector(
  selectPatientContactState,
  fromPatientContact.selectPatientContactEntities,
);

export const selectAllPatientContacts = createSelector(selectPatientContactState, fromPatientContact.selectAllContacts);

export const selectPatientContactTotal = createSelector(
  selectPatientContactState,
  fromPatientContact.selectContactTotal,
);

export const selectPatientContactById = createSelector(
  selectRouteParams,
  selectPatientContactEntities,
  ({ id }, contacts: Dictionary<PatientContact>) =>
    id && (contacts[id] ?? contacts[id.toLowerCase()] ?? contacts[id.toUpperCase()]),
);

export const selectAllPatientContactViewModels = createSelector(
  selectAllPatientContacts,
  (dataSource: PatientContact[]) => dataSource.map((item) => new PatientContactViewModel(item, 'R3')), //TODO: Replace with this.fhirVersion or similar when this will use Patient resource
);

export const selectPatientContactViewModelById = createSelector(
  selectPatientContactById,
  (entity: PatientContact | undefined) => entity && new PatientContactViewModel(entity, 'R3'), //TODO: Replace with this.fhirVersion or similar when this will use Patient resource
);
