import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const MateVanKritiekZijnCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.8.2.4--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '255604002': {
        'nl-NL': 'Licht',
        display: 'Mild',
      },
      '6736007': {
        'nl-NL': 'Matig',
        display: 'Moderate',
      },
      '24484000': {
        'nl-NL': 'Ernstig',
        display: 'Severe',
      },
      '399166001': {
        'nl-NL': 'Fataal',
        display: 'Fatal',
      },
    },
  },
};
