import { Specimen } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectSpecimenId(specimen: Specimen): string {
  if (!specimen.id) throw new Error('The specimen is not created yet.');

  return specimen.id;
}

export type State = EntityState<Specimen>;

export const adapter: EntityAdapter<Specimen> = createEntityAdapter<Specimen>({
  selectId: selectSpecimenId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { specimens }) => {
    return adapter.upsertMany(specimens, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDiagnosticReportEntities = selectEntities;

export const selectAllDiagnosticReports = selectAll;
