import { MedicationRequest } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectMedicationRequestId(medicationRequest: MedicationRequest): string {
  if (!medicationRequest.id) throw new Error('The medication request is not created yet.');

  return medicationRequest.id;
}

export type State = EntityState<MedicationRequest>;

export const adapter: EntityAdapter<MedicationRequest> = createEntityAdapter<MedicationRequest>({
  selectId: selectMedicationRequestId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { medicationRequests }) => {
    return adapter.upsertMany(medicationRequests, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectMedicationRequestEntities = selectEntities;

export const selectAllMedicationRequests = selectAll;
