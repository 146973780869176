import { MedicationRequest } from '@hl7fhir';
import { MedicationRequestViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromMedicationRequest from './medication-request.reducer';

export const selectMedicationRequestState = createFeatureSelector<fromMedicationRequest.State>(
  features.FHIR.medicationRequest,
);

export const selectMedicationRequestEntities = createSelector(
  selectMedicationRequestState,
  fromMedicationRequest.selectMedicationRequestEntities,
);

export const selectAllMedicationRequests = createSelector(
  selectMedicationRequestState,
  fromMedicationRequest.selectAllMedicationRequests,
);

export const selectMedicationRequestById = createSelector(
  selectRouteParams,
  selectMedicationRequestEntities,
  ({ id }, medicationRequests: Dictionary<MedicationRequest>) =>
    id && (medicationRequests[id] ?? medicationRequests[id.toLowerCase()] ?? medicationRequests[id.toUpperCase()]),
);

export const selectAllMedicationRequestViewModels = createSelector(selectAllMedicationRequests, (medicationRequests) =>
  medicationRequests.map((medicationRequest) => new MedicationRequestViewModel(medicationRequest)),
);

export const selectMedicationRequestViewModelById = createSelector(
  selectMedicationRequestById,
  (medicationRequest) => medicationRequest && new MedicationRequestViewModel(medicationRequest),
);
