import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, AppState, BackButtonListenerEvent, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Store } from '@ngrx/store';
import { IDLE_ACTIONS } from '@store/app';
import { lastValueFrom } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class AppListenersService {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store,
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
  ) {}

  init(): void {
    App.removeAllListeners();

    this.createAppStateChangeListener();
    this.createAppUrlOpenListener();
    this.createBackButtonListener();
  }

  private createAppStateChangeListener(): void {
    // After a certain period, OS may perform a hard reload if the app has been in the background for an extended
    // time. This action will cause the app to reinitialize. In this scenario, it is necessary to eliminate the idle
    // start time since the app will be starting anew.
    this.localStorageService.remove(LocalStorageKeys.idleStart);

    App.addListener('appStateChange', async (appState: AppState) => {
      if (appState.isActive) {
        // Check if start date of app inactivity is set
        // If it is, check if the app was inactive for more than 10 minutes
        // If it was, start the biometric authentication process or log the user out
        const idleStart = this.localStorageService.read(LocalStorageKeys.idleStart);

        if (idleStart) {
          const diff = Date.now() - parseInt(idleStart, 10);

          const tenMinutesInMs = 600000;
          if (diff >= tenMinutesInMs) {
            const isBiometryEnabled = await lastValueFrom(this.authenticationService.useBiometrics$);

            if (isBiometryEnabled) {
              this.store.dispatch(IDLE_ACTIONS.end());
            } else {
              // If the user has not enabled biometrics, log them out
              this.store.dispatch(IDLE_ACTIONS.timeout());
            }
          }

          // Remove the idle start time.
          // We don't need it anymore since the app is active again.
          this.localStorageService.remove(LocalStorageKeys.idleStart);
        }
      } else {
        // Set the start date of app inactivity when the app goes into the background state.
        localStorage.setItem(LocalStorageKeys.idleStart, Date.now().toString());
      }
    });
  }

  private createAppUrlOpenListener(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        let slug = event.url.split(':/').pop();
        if (slug) {
          slug = slug.replace('callback/', 'callback');
          slug = slug.replace('return/', 'return');
          slug = slug.replace('return', `${$localize.locale}/return`);
          slug = slug.replace('onboard-or-log-in/', 'onboard-or-log-in');
          slug = slug.replace('settings/', 'settings');
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  private createBackButtonListener(): void {
    App.addListener('backButton', (event: BackButtonListenerEvent) => {
      if (event.canGoBack && Capacitor.getPlatform() === 'android') {
        window.history.back();
      }
    });
  }
}
