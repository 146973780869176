import { createSelector } from '@ngrx/store';
import { selectDigiMeState } from './digi-me.selectors';

/**
 * Selects and returns the  push account for data sharing.
 *
 * @param state - The digi.me state.
 * @returns The sharing account.
 */
export const selectSharingAccount = createSelector(selectDigiMeState, (state) => state.share.account);

/**
 * Selects and returns the data that is to be shared.
 *
 * @param state - The digi.me state.
 * @returns The data to be shared.
 */
export const selectDataToBeShared = createSelector(selectDigiMeState, (state) => state.share.data);

export const selectShareableAccountData = createSelector(
  selectSharingAccount,
  selectDataToBeShared,
  (account, data) => {
    if (!account || !data) {
      return undefined;
    }

    return { account, data };
  },
);
