import { AccountsResponse } from '@digi.me/models';
import { Observation } from '@hl7fhir';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const OBSERVATION_UI_ACTIONS = createActionGroup({
  source: 'Observations UI',
  events: {
    'Add To List': props<{ observation: Observation }>(),
    Remove: props<{ id: string; folder: string; navigateTo: string }>(),
    'Remove Success': props<{ id: string }>(),
    'Remove Failed': props<{ error: any }>(),
  },
});

export const SHARE_OBSERVATION_ACTIONS = createActionGroup({
  source: 'Share Observation UI',
  events: {
    'Find Account Succeeded': props<{ account: AccountsResponse }>(),
    Reset: emptyProps(),
  },
});
