export enum ResourceTypes {
  AllergyIntolerance = 27, // +
  Appointment = 53, // +
  Composition = 20, // +
  CarePlan = 59, // -
  CareTeam = 60, // -
  Condition = 13, // +
  Consent = 71, // +
  Coverage = 73, // +
  DeviceRequest = 78, // -
  DeviceUseStatement = 79, // +
  DiagnosticReport = 26, // -
  DocumentManifest = 80, // +
  DocumentReference = 81, // +
  Encounter = 32, // +
  EpisodeOfCare = 36, // +
  Flag = 89, // +
  Immunization = 14, // +
  ImmunizationRecommendation = 97, // +
  MedicationDispense = 40, // +
  MedicationRequest = 30, // +
  MedicationStatement = 34, // +
  NutritionOrder = 110, // +
  Observation = 23, // +
  Patient = 37, // +
  PractitionerRole = 118, // +
  Procedure = 25, // +
  ProcedureRequest = 199, // -
}
