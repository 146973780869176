import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  AuthorizationDetails,
  CAFileListEntry,
  CAFileListResponse,
  DeleteAccount,
  DigiMeFile,
  MobileVersionDetails,
  PortabilityReport,
  ReadAccountsResponse,
  ReauthorizationDetails,
  RevokeDetails,
  SourceType,
  UserAD,
  UserResponse,
  getDistinctId,
} from '@digi.me/models';
import { environment } from '@environments/environment';
import { ApiUrls, DownloadStorageFileResponse, ListStorageFilesResponse, StorageFile, StorageResource } from '@globals';
import {
  BehaviorSubject,
  Observable,
  defer,
  from,
  map,
  mergeAll,
  mergeMap,
  pairwise,
  repeat,
  retry,
  startWith,
  takeWhile,
  tap,
  timeout,
} from 'rxjs';
import { DeviceService } from './device.service';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _fileList$ = new BehaviorSubject<CAFileListResponse | null>(null);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly deviceService: DeviceService,
  ) {}

  get fileList$() {
    return this._fileList$.asObservable();
  }

  readAccounts(): Observable<ReadAccountsResponse> {
    return this.httpClient.get<ReadAccountsResponse>(ApiUrls.READ_ACCOUNTS);
  }

  getAuthorizeUrl(
    useScheme: boolean,
    createNewUser: boolean,
    sourceFetch: boolean,
    sourceType: SourceType,
  ): Observable<AuthorizationDetails> {
    let params: Record<string, string | boolean> = {
      language: $localize.locale ?? 'en-US',
      scheme: useScheme,
      sourceFetch,
      sourceType,
    };

    if (createNewUser) {
      const distinctId = getDistinctId();
      params = { ...params, create: true, distinctId: distinctId };
    }

    return this.httpClient.get<AuthorizationDetails>(ApiUrls.AUTHORIZE_URL, {
      params: params,
    });
  }

  getRevoke(accountId: string, useScheme: boolean): Observable<RevokeDetails> {
    const params: Record<string, string | boolean> = {
      language: $localize.locale ?? 'en-US',
      scheme: useScheme,
      accountId: accountId,
    };
    return this.httpClient.get<RevokeDetails>(ApiUrls.REVOKE, {
      params: params,
    });
  }

  deleteAccount(accountId: string): Observable<DeleteAccount> {
    return this.httpClient.post<DeleteAccount>(ApiUrls.DELETE_ACCOUNT, {
      accountId: accountId,
    });
  }

  updateAdUser(adUser: UserAD): Observable<UserAD> {
    return this.httpClient.post<UserAD>(ApiUrls.UPDATE_AD_USER, {
      adUser: adUser,
    });
  }

  getAdUser(): Observable<UserAD> {
    return this.httpClient.get<UserAD>(ApiUrls.AD_USER);
  }

  getPortabilityReportUrl(from?: number, to?: number): Observable<PortabilityReport> {
    return this.httpClient.get<PortabilityReport>(ApiUrls.PORTABILITY_REPORT, {
      params: {
        from: from?.toString() || '',
        to: to?.toString() || '',
      },
    });
  }

  eventLog(event: string, properties: any): Observable<HttpResponse<any>> {
    const deviceId = this.deviceService.deviceId;

    return this.httpClient.post<HttpResponse<any>>(ApiUrls.EVENT_LOG, {
      event: event,
      properties: properties,
      deviceId: deviceId,
    });
  }

  getReauthorizeUrl(accountId: string, useScheme: boolean): Observable<ReauthorizationDetails> {
    return this.httpClient.get<ReauthorizationDetails>(ApiUrls.REAUTHORIZE_URL, {
      params: {
        accountId: accountId,
        language: $localize.locale ?? 'en-US',
        scheme: useScheme,
      },
    });
  }

  getVersionCheck(): Observable<MobileVersionDetails> {
    return this.httpClient.get<MobileVersionDetails>(ApiUrls.VERSION_CHECK, {
      params: {
        version: environment.version,
        platform: Capacitor.getPlatform(),
      },
    });
  }

  confirm() {
    return this.httpClient.post<HttpResponse<any>>(ApiUrls.CONFIRM_URL, undefined);
  }

  exchangeCodeForToken(code: string, success: string, library?: string | undefined): Observable<HttpResponse<any>> {
    let params: Record<string, string> = {
      code: code,
      success: success,
    };
    if (library) {
      params = { ...params, library: library };
    }
    return this.httpClient.post<HttpResponse<any>>(ApiUrls.EXCHANGE_CODE_FOR_TOKEN, '', {
      params: params,
      observe: 'response',
    });
  }

  claim(library: string): Observable<HttpResponse<any>> {
    const params: Record<string, string> = {
      library: library,
    };
    return this.httpClient.post<HttpResponse<any>>(ApiUrls.CLAIM, '', {
      params: params,
      observe: 'response',
    });
  }

  /**
   * Retrieves a list of DigiMe files from the API.
   *
   * @param sourceFetch A boolean indicating whether to fetch the files from the source.
   * @param accountId The ID of the user account.
   * @returns An Observable that emits an array of DigiMeFile objects.
   */
  getFiles(sourceFetch: boolean, accountId?: string): Observable<DigiMeFile> {
    let i = 0;
    return (
      defer(
        () =>
          this.httpClient.get<CAFileListResponse>(`${ApiUrls.FILE_LIST}`, {
            params: {
              sourceFetch: sourceFetch && i++ === 0,
              ...(accountId && { accountId }),
            },
            observe: 'response',
          }), // Use defer to update the source fetch to false for consecutive calls
      )
        .pipe(
          repeat({ delay: 1000 }), // Repeate each second
          takeWhile((res) => !(res.status === 200 || res.status === 206), true), // While we don't get a success or partial result
          map((res) => res.body), // we are interested in the body
          tap((fileList) => {
            this._fileList$.next(fileList);
          }), // TODO: If needed see if we can do this more elegantly, this works though
          startWith(null), // start the first pairwise with an empty value
          pairwise(),
          map(([previous, current]) =>
            current?.fileList?.filter((file) => {
              return !previous?.fileList?.some((existing) => existing.name === file.name);
            }),
          ),
          map((files: CAFileListEntry[] | undefined) => {
            // As to spec: https://digi-me.atlassian.net/wiki/spaces/DM/pages/3447521281/Documents#Load-binaries-only-when-required
            // Filter out binaries
            return files?.filter((file) => !file.name.includes('_502_'));
          }),
        )
        // Second pipe is needed to keep the typings
        .pipe(
          mergeMap((files: CAFileListEntry[] | undefined) => {
            files ??= [];
            return from(files).pipe(
              map((fileMeta) => this.getFile(fileMeta.name)),
              mergeAll(3),
            );
          }),
          map((response: HttpResponse<DigiMeFile>) => response.body!),
          timeout<DigiMeFile>(180000), // Timeout after 3 minutes
        )
    );
  }

  getStorageFiles(): Observable<StorageResource> {
    return this.httpClient
      .get<ListStorageFilesResponse>(`${ApiUrls.STORAGE_FILE_LIST}`, {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<ListStorageFilesResponse>) => {
          return response.body?.files;
        }),
      )
      .pipe(
        mergeMap((files: StorageFile[] | undefined) => {
          files ??= [];
          return from(files).pipe(
            map((file: StorageFile) => this.getStorageFile(file.path + file.name)),
            mergeAll(3),
          );
        }),
        map((response: HttpResponse<any>) => response.body!),
      );
  }

  getFileList(sourceFetch: boolean): Observable<HttpResponse<CAFileListResponse>> {
    return this.httpClient.get<CAFileListResponse>(`${ApiUrls.FILE_LIST}`, {
      params: {
        sourceFetch,
      },
      observe: 'response',
    });
  }

  getFile(fileName: string): Observable<HttpResponse<DigiMeFile>> {
    return this.httpClient.get<DigiMeFile>(`${ApiUrls.FILE}`, {
      params: {
        fileName: fileName,
      },
      observe: 'response',
    });
  }

  reset(deleteParam = false): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(ApiUrls.RESET, '', {
      params: { delete: deleteParam },
      observe: 'response',
    });
  }

  getUser(): Observable<UserResponse> {
    return this.httpClient.get<UserResponse>(ApiUrls.USER);
  }

  /**
   * Sends a POST request to push data to a healthcare provider.
   * ! At the moment, it is not possible to push data to the library.
   *
   * @param accountId - The ID of the account.
   * @param data - The data to be pushed.
   * @returns An Observable that emits an HttpResponse<any> object.
   */
  push(accountId: string, data: any): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(
      ApiUrls.PUSH_DATA,
      {
        data,
      },
      {
        params: { accountId },
        observe: 'response',
      },
    );
  }

  getStorageFile(filePath = ''): Observable<HttpResponse<DownloadStorageFileResponse>> {
    return this.httpClient.get<DownloadStorageFileResponse>(ApiUrls.STORAGE_FILE, {
      params: { filePath },
      observe: 'response',
    });
  }

  addStorageFile(file: StorageResource, fileName: string, filePath?: string): Observable<HttpResponse<any>> {
    const json = JSON.stringify(file);
    return this.httpClient
      .post<HttpResponse<any>>(
        ApiUrls.UPLOAD_STORAGE_FILE,
        {
          file: json,
        },
        {
          params: { fileName, ...(filePath && { filePath }) },
          observe: 'response',
        },
      )
      .pipe(retry({ count: 3, delay: 1000 }));
  }

  deleteStorageFile(filePath = ''): Observable<HttpResponse<any>> {
    return this.httpClient.delete<HttpResponse<any>>(ApiUrls.DELETE_STORAGE_FILE, {
      params: { filePath },
      observe: 'response',
    });
  }

  getReviewUrl(): Observable<string> {
    return this.httpClient.get<string>(ApiUrls.REVIEW_URL);
  }
}
