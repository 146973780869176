import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AndroidBiometryStrength, BiometricAuth, CheckBiometryResult } from '@aparajita/capacitor-biometric-auth';
import { Capacitor } from '@capacitor/core';
import { GetResult, Preferences } from '@capacitor/preferences';
import { configId } from '@environments/environment';
import { PlatformTypes, PreferencesKeys } from '@globals';
import { ConfigAuthenticatedResult, LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, filter, from, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    readonly router: Router,
    readonly oidcSecurityService: OidcSecurityService,
  ) {}

  get showUseBiometricsModal$(): Observable<boolean> {
    // No need to execute the further logic if the platform is not native
    if (!Capacitor.isNativePlatform()) {
      return of(false);
    }

    return combineLatest([
      // Show only if the app opens for the first time or after the user logs out, then we assume that the user is new
      this.useBiometricsModal$,
      //  Show only if user is authenticated
      this.oidcSecurityService.isAuthenticated$,
    ]).pipe(
      map(
        ([useBiometricsModal, authenticationResult]) =>
          useBiometricsModal &&
          authenticationResult.allConfigsAuthenticated.some(
            (config: ConfigAuthenticatedResult) => config.configId === configId && config.isAuthenticated,
          ),
      ),
    );
  }

  /**
   * Check to see what biometry type (if any) is available.
   */
  get checkBiometry$(): Observable<CheckBiometryResult> {
    return from(BiometricAuth.checkBiometry());
  }

  /**
   * Check if the user has enabled biometrics.
   */
  get useBiometrics$(): Observable<boolean> {
    // No need to execute the further logic if the platform is not native
    if (!Capacitor.isNativePlatform()) {
      return of(false);
    }

    const result = Preferences.get({ key: PreferencesKeys.ENABLE_BIOMETRICS });
    return from(result).pipe(map((res: GetResult) => res.value === 'true'));
  }

  /**
   * Show/Hide the modal that asks the user if they want to enable biometric authentication.
   * It is only shown once when the user opens the app for the first time or after they log out.
   * Then we assume that the user is new, and we display the modal again.
   */
  get useBiometricsModal$(): Observable<boolean> {
    // No need to execute the logic if the platform is not native
    if (!Capacitor.isNativePlatform()) {
      return of(false);
    }

    return combineLatest([
      from(Preferences.get({ key: PreferencesKeys.HIDE_USE_BIOMETRICS_MODAL })),
      this.checkBiometry$,
    ]).pipe(map(([preferences, biometry]) => biometry.isAvailable && !(preferences.value === 'true')));
  }

  async setUseBiometrics(value: boolean): Promise<void> {
    await Preferences.set({ key: PreferencesKeys.ENABLE_BIOMETRICS, value: value.toString() });

    if (value && Capacitor.getPlatform() === PlatformTypes.iOS) {
      await BiometricAuth.authenticate();
    }
  }

  async setHideUseBiometricsModal(value: boolean): Promise<void> {
    await Preferences.set({ key: PreferencesKeys.HIDE_USE_BIOMETRICS_MODAL, value: value.toString() });
  }

  authenticate(): Observable<LoginResponse> {
    return this.useBiometrics$.pipe(
      switchMap((useBiometrics: boolean) =>
        this.oidcSecurityService
          .checkAuth(`${window.location.origin}`, configId)
          .pipe(map((loginResponse: LoginResponse) => ({ loginResponse, useBiometrics }))),
      ),
      switchMap(({ loginResponse, useBiometrics }) =>
        // If the user starts the app, we can reasonably assume that they are not authenticated yet.
        // If the user has already opened the app and is authenticated, and if the app reloads during usage, this may
        // indicate that the user has programmatically reloaded the app, such as after removing a healthcare provider.
        // Under these circumstances, it is not necessary to re-authenticate using biometrics; instead, the app should
        // continue with the existing authentication state.
        !loginResponse.isAuthenticated && useBiometrics ? this.authenticateUsingBiometrics() : of(loginResponse),
      ),
    );
  }

  /**
   * Authenticate the user using biometrics
   */
  authenticateUsingBiometrics(): Observable<LoginResponse> {
    return combineLatest([this.useBiometrics$, this.checkBiometry$]).pipe(
      filter(([useBiometrics, biometryResult]) => useBiometrics && biometryResult.isAvailable),
      tap(async () => {
        const { value } = await Preferences.get({ key: configId });
        sessionStorage.setItem(configId, value!);
      }),
      switchMap(() =>
        from(
          BiometricAuth.authenticate({
            allowDeviceCredential: true,
            androidConfirmationRequired: false,
            androidBiometryStrength: AndroidBiometryStrength.weak,
          }),
        ).pipe(switchMap(() => this.oidcSecurityService.checkAuth(`${window.location.origin}`, configId))),
      ),
    );
  }
}
