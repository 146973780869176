import { Organization } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { OrganizationViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromOrganization from './organization.reducer';

export const selectOrganizationState = createFeatureSelector<fromOrganization.State>(features.FHIR.organizations);

export const selectOrganizationIds = createSelector(selectOrganizationState, fromOrganization.selectOrganizationIds);

export const selectOrganizationEntities = createSelector(
  selectOrganizationState,
  fromOrganization.selectOrganizationEntities,
);

export const selectAllOrganizations = createSelector(selectOrganizationState, fromOrganization.selectAllOrganizations);

export const selectOrganizationTotal = createSelector(
  selectOrganizationState,
  fromOrganization.selectOrganizationTotal,
);

export const selectOrganizationById = createSelector(
  selectRouteParams,
  selectOrganizationEntities,
  ({ id }, organizations: Dictionary<Organization>) =>
    id && (organizations[id] ?? organizations[id.toLowerCase()] ?? organizations[id.toUpperCase()]),
);

export const selectOrganizationsWithoutUZOVI = createSelector(selectAllOrganizations, (organizations) =>
  organizations.filter((organization) => !hasUZoVI(organization)),
);

export const selectOrganizationWithoutUZOVIViewModels = createSelector(
  selectOrganizationsWithoutUZOVI,
  (dataSource: Organization[]) => dataSource.map((item) => new OrganizationViewModel(item)),
);

export const selectOrganizationViewModelById = createSelector(
  selectOrganizationById,
  (entity: Organization | undefined) => entity && new OrganizationViewModel(entity),
);

function hasUZoVI(organization: Organization) {
  if (!organization || !organization.identifier || !organization.identifier.length) {
    return false;
  }

  return organization.identifier.some((identifier) => identifier.system === CodeSystems.UZOVI);
}
