import { formatLocaleDate } from '@globals';
import { ImagingStudySeries } from '@hl7fhir';
import { CodingsPipe } from '@hl7fhir/data-types';
import { getReferences } from '@hl7fhir/foundation';
import { AvailabilityPipe } from '@hl7fhir/value-sets';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { ImagingStudySeriesInstanceViewModel } from './imaging-study-series-instance.viewmodel';

export class ImagingStudySeriesViewModel extends BackboneElementViewModel<ImagingStudySeries> {
  get availability(): string | undefined {
    const imagingStudySeriesR3 = this.element as r3.ImagingStudySeries;
    if (this.fhirVersion === 'R3') {
      return new AvailabilityPipe().transform(imagingStudySeriesR3?.availability);
    }

    return undefined;
  }

  get description(): string | undefined {
    return this.element?.description;
  }

  get number(): string | undefined {
    return this.element?.number?.toString();
  }

  get numberOfInstances(): string | undefined {
    return this.element?.numberOfInstances?.toString();
  }

  get started(): string | undefined {
    return formatLocaleDate(this.element?.started);
  }

  get uid(): string | undefined {
    return this.element?.uid;
  }

  get bodySite(): string | undefined {
    const imagingStudySeriesR = this.element as r3.ImagingStudySeries | r4.ImagingStudySeries | r4b.ImagingStudySeries;
    return new CodingsPipe().transform(imagingStudySeriesR?.bodySite);
  }

  get endpoint(): string | undefined {
    return getReferences(this.element?.endpoint);
  }

  get laterality(): string | undefined {
    const imagingStudySeriesR = this.element as r3.ImagingStudySeries | r4.ImagingStudySeries | r4b.ImagingStudySeries;
    return new CodingsPipe().transform(imagingStudySeriesR?.laterality);
  }

  get modality(): string | undefined {
    return new CodingsPipe().transform(this.element?.modality);
  }

  get performer(): string | undefined {
    return getReferences(this.element?.performer);
  }

  get instance(): ImagingStudySeriesInstanceViewModel[] | undefined {
    const imagingStudySeriesR = this.element as r3.ImagingStudySeries | r4.ImagingStudySeries | r4b.ImagingStudySeries;
    return imagingStudySeriesR?.instance?.map(
      (instance) => new ImagingStudySeriesInstanceViewModel(instance, this.fhirVersion),
    );
  }
}
