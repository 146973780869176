<div *ngIf="dateTime">
  <span data-cy="date-time">{{ formattedDateTime }}</span>
</div>

<div *ngIf="age">
  <span data-cy="age">{{ age | age }}</span>
</div>

<div *ngIf="period">
  <span data-cy="period">{{ period | period }}</span>
</div>

<!-- TODO: Implement -->
<div *ngIf="range"><span data-cy="range"></span></div>

<div *ngIf="string">
  <span data-cy="string">{{ string }}</span>
</div>

<!-- TODO: Test -->
<div *ngIf="boolean">
  <span data-cy="boolean">{{ boolean | yesno }}</span>
</div>

<div *ngIf="instant">
  <span data-cy="instant">{{ instant | date: 'longDate' }}</span>
</div>

<!-- TODO: Implement -->
<div *ngIf="timing"><span data-cy="timing"></span></div>

<div *ngIf="quantity">
  <span data-cy="quantity">{{ quantity | quantity }}</span>
</div>

<!-- TODO: Test -->
<div *ngIf="codeableConcept">
  <span data-cy="codeable-concept">{{
    codeableConcept | codeableConcept
    }}</span>
</div>

<!-- TODO: Test -->
<div *ngIf="integer">
  <span data-cy="integer">{{ integer }}</span>
</div>

<!-- TODO: Implement -->
<div *ngIf="ratio"><span data-cy="ratio"></span></div>

<!-- TODO: Implement -->
<div *ngIf="sampledData"><span data-cy="sample-data"></span></div>

<!-- TODO: Implement -->
<div *ngIf="time"><span data-cy="time"></span></div>

<!-- TODO: Implement -->
<div *ngIf="attachment"><span data-cy="attachment"></span></div>

<div *ngIf="reference">
  <app-reference [reference]="reference"
                 data-cy="reference"></app-reference>
</div>