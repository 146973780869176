import { Component } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChoiceOfType } from '../choice-of-type.model';

@Component({
  selector: 'app-choice-of-types-cell-renderer',
  templateUrl: './choice-of-types-cell-renderer.component.html',
})
export class ChoiceOfTypesCellRendererComponent implements ICellRendererAngularComp {
  params: ChoiceOfType | undefined;

  agInit(params: ICellRendererParams<any, any, any> & ChoiceOfType): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
