import { ContactPoint, PatientContact } from '@hl7fhir';
import {
  AddressViewModel,
  CodeableConceptPipe,
  ContactPointViewModel,
  FullNamePipe,
  HumanNameViewModel,
  PeriodTypePipe,
} from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { RelatieCodelijst, RolCodelijst } from '@hl7nl-fhir/value-sets';

export class PatientContactViewModel extends BackboneElementViewModel<PatientContact> {
  get name(): string | undefined {
    return new FullNamePipe().transform(this.element?.name);
  }

  get humanName(): HumanNameViewModel | undefined {
    return new HumanNameViewModel(this.element?.name, this.fhirVersion);
  }

  get gender(): string | undefined {
    return this.element?.gender;
  }

  get contactPoints(): ContactPointViewModel[] | undefined {
    return this.element?.telecom?.map(
      (contactPoint: ContactPoint) => new ContactPointViewModel(contactPoint, this.fhirVersion),
    );
  }

  get address(): AddressViewModel | undefined {
    return new AddressViewModel(this.element?.address, this.fhirVersion);
  }

  get organization(): string | undefined {
    return getReference(this.element?.organization);
  }

  get period(): string | undefined {
    return new PeriodTypePipe().transform(this.element?.period);
  }

  get relationship(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.relationship, [RelatieCodelijst, RolCodelijst]);
  }
}
