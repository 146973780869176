import { Injectable } from '@angular/core';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { MeasurementType } from '@digi.me/models';
import { ObservationStorage } from '@globals';
import { Store } from '@ngrx/store';
import { selectUserAd, STORAGE_FILE_API_ACTIONS } from '@store/digi.me';
import { DigiMePluginResult, DigiPlugin } from 'digi-plugin/src';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class AppleHealthUploadService {
  private readonly userAd$ = this.store.select(selectUserAd);

  constructor(private store: Store) {}

  public async addAppleHealth(): Promise<void> {
    try {
      const fileUrls = await this.fetchHealthData();
      await this.uploadFiles(fileUrls);
    } catch (error) {
      // Added error handler for correct further work
      void error;
    }
  }

  private async fetchHealthData(): Promise<string[]> {
    const userAd = await firstValueFrom(
      this.userAd$.pipe(filter((user): user is NonNullable<typeof user> => user !== null && user !== undefined)),
    );

    if (!userAd.patientId) {
      throw new Error('Patient ID not available');
    }

    const params = {
      patientId: userAd.patientId,
    };

    const result: DigiMePluginResult = await DigiPlugin.fetchHealthData(params);
    if (result.success && result.values) {
      return result.values as string[];
    } else {
      throw new Error(result.error || 'Unknown error occurred');
    }
  }

  private async uploadFiles(fileUrls: string[]): Promise<void> {
    for (const url of fileUrls) {
      try {
        await this.processFile(url);
      } catch (error) {
        // Added error handler for correct next files
        void error;
      }
    }
  }

  private async processFile(url: string): Promise<void> {
    const fileName = url.split('/').pop();
    if (!fileName) {
      throw new Error(`Invalid file URL: ${url}`);
    }

    const contents = await Filesystem.readFile({
      path: fileName,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    });

    if (typeof contents.data !== 'string') {
      throw new Error(`Unexpected data type for file ${fileName}`);
    }

    const jsonData = JSON.parse(contents.data);
    const measurementType = await this.getMeasurementTypeFromContent(jsonData);

    if (measurementType) {
      const observations = Array.isArray(jsonData) ? jsonData : [jsonData];
      await this.uploadObservations(observations, measurementType);
    }
  }

  private async uploadObservations(observations: any[], measurementType: MeasurementType): Promise<void> {
    for (const observation of observations) {
      if (!observation.id) {
        observation.id = uuidv4();
      }

      const uniqueFileName = `${observation.id}.json`;

      this.store.dispatch(
        STORAGE_FILE_API_ACTIONS.addAppleHealthStorageFile({
          file: observation,
          fileName: uniqueFileName,
          filePath: ObservationStorage.FOLDERS.APPLE_HEALTH,
          measurementType: measurementType,
        }),
      );
    }
  }

  private async getMeasurementTypeFromContent(fileContent: any): Promise<MeasurementType | null> {
    if (!Array.isArray(fileContent) || fileContent.length === 0) {
      return null;
    }
    const observation = fileContent[0];
    if (observation.resourceType !== 'Observation') {
      return null;
    }
    // Check for code.coding array
    if (Array.isArray(observation.code?.coding)) {
      const coding = observation.code.coding[0];
      switch (coding.code) {
        case '29463-7':
          return MeasurementType.weight;
        case '85354-9':
          return MeasurementType.bloodPressure;
        case '14743-9':
          return MeasurementType.bloodGlucose;
        case '8310-5':
          return MeasurementType.bodyTemperature;
        case '2708-6':
          return MeasurementType.o2Saturation;
        case '8302-2':
          return MeasurementType.height;
        case '8867-4':
          return MeasurementType.heartRate;
      }
    }

    // Check for component array (for respiratory rate)
    if (Array.isArray(observation.component)) {
      const component = observation.component[0];
      if (component.code?.coding?.[0]?.code === '9279-1') {
        return MeasurementType.respirationRate;
      }
    }
    // Check for identifier array
    if (Array.isArray(observation.identifier)) {
      const identifier = observation.identifier[0]?.value;
      switch (identifier) {
        case 'HKQuantityTypeIdentifierBodyMass':
          return MeasurementType.weight;
        case 'HKCorrelationTypeIdentifierBloodPressure':
          return MeasurementType.bloodPressure;
        case 'HKQuantityTypeIdentifierBloodGlucose':
          return MeasurementType.bloodGlucose;
        case 'HKQuantityTypeIdentifierBodyTemperature':
          return MeasurementType.bodyTemperature;
        case 'HKQuantityTypeIdentifierOxygenSaturation':
          return MeasurementType.o2Saturation;
        case 'HKQuantityTypeIdentifierRespiratoryRate':
          return MeasurementType.respirationRate;
        case 'HKQuantityTypeIdentifierHeight':
          return MeasurementType.height;
        case 'HKQuantityTypeIdentifierHeartRate':
          return MeasurementType.heartRate;
      }
    }
    // If no match found
    return null;
  }
}
