import { ElementViewModel } from '@hl7fhir/viewmodels';
import { CodeableConcept, ContactPoint, Extension } from '@hl7fhir';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { TelecomTypeCodelijst } from '@hl7nl-fhir/value-sets';
import { SelectPipe } from '@shared';
import { CodeableConceptPipe } from '../codeable-concept';
import { ContactPointUsePipe } from './pipes/contact-point-use-pipe.pipe';
import { ContactPointSystemWherePipe } from './pipes/contact-point-system-where.pipe';
import { ContactPointRankPipe } from './pipes/contact-point-rank.pipe';

export class ContactPointViewModel extends ElementViewModel<ContactPoint> {
  get system(): string | undefined {
    switch (this.element?.system) {
      case 'phone':
        return $localize`:@@fhir.contact-point-system.phone:Phone`;
      case 'fax':
        return $localize`:@@fhir.contact-point-system.fax:Fax`;
      case 'email':
        return $localize`:@@fhir.contact-point-system.mail:Email`;
      case 'pager':
        return $localize`:@@fhir.contact-point-system.pager:Pager`;
      case 'url':
        return $localize`:@@fhir.contact-point-system.url:URL`;
      case 'sms':
        return $localize`:@@fhir.contact-point-system.sms:SMS`;
      case 'other':
        return $localize`:@@fhir.contact-point-system.other:Other`;
      default:
        return '';
    }
  }

  get value(): string | undefined {
    return this.element?.value;
  }

  get use(): string | undefined {
    return new ContactPointUsePipe().transform(this.element);
  }

  get type(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.element?.extension,
      StructureDefinition.Nictiz.CONTACT_INFORMATION.telecomType,
    );
    const codeableConcept: CodeableConcept | undefined = new SelectPipe().transform(extension, 'valueCodeableConcept');

    return new CodeableConceptPipe().transform(codeableConcept, [TelecomTypeCodelijst]);
  }
}

export function getPhone(contactPoints: ContactPoint[] | undefined): string | undefined {
  const contactPointSystem: ContactPoint[] = new ContactPointSystemWherePipe().transform(contactPoints, 'phone');
  const contactPointRank: ContactPoint | null = new ContactPointRankPipe().transform(contactPointSystem);
  return contactPointRank?.value;
}

export function getEmail(contactPoints: ContactPoint[] | undefined): string | undefined {
  const contactPointSystem: ContactPoint[] = new ContactPointSystemWherePipe().transform(contactPoints, 'email');
  const contactPointRank: ContactPoint | null = new ContactPointRankPipe().transform(contactPointSystem);
  return contactPointRank?.value;
}
