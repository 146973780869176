import { formatLocaleDate } from '@globals';
import { ImmunizationReaction } from '@hl7fhir';
import { YesNoPipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';

export class ImmunizationReactionViewModel extends BackboneElementViewModel<ImmunizationReaction> {
  get date(): string | undefined {
    return this.element?.date && formatLocaleDate(this.element.date);
  }

  get detail(): string | undefined {
    const reactionR = this.element as r3.ImmunizationReaction | r4.ImmunizationReaction | r4b.ImmunizationReaction;

    const reactionR5 = this.element as r5.ImmunizationReaction;

    return (
      (reactionR.detail && getReference(reactionR.detail)) ??
      (reactionR5.manifestation && getReference(reactionR5.manifestation?.reference))
    );
  }

  get reported(): string | undefined {
    if (!this.element?.reported) {
      return undefined;
    }

    return new YesNoPipe().transform(this.element.reported);
  }
}
