import { Appointment } from '@hl7fhir';
import { AppointmentViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromAppointment from './appointment.reducer';

export const selectAppointmentState = createFeatureSelector<fromAppointment.State>(features.FHIR.appointments);

export const selectAppointmentEntities = createSelector(
  selectAppointmentState,
  fromAppointment.selectAppointmentEntities,
);

export const selectAllAppointments = createSelector(selectAppointmentState, fromAppointment.selectAllAppointments);

export const selectAppointmentById = createSelector(
  selectRouteParams,
  selectAppointmentEntities,
  ({ id }, appointments: Dictionary<Appointment>) =>
    id && (appointments[id] ?? appointments[id.toLowerCase()] ?? appointments[id.toUpperCase()]),
);

export const selectAllAppointmentViewModels = createSelector(selectAllAppointments, (dataSource: Appointment[]) =>
  dataSource.map((item) => new AppointmentViewModel(item)),
);

export const selectAppointmentViewModelById = createSelector(
  selectAppointmentById,
  (appointment: Appointment | undefined) => appointment && new AppointmentViewModel(appointment),
);
