import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private readonly deviceIdKey = 'device_id';

  get deviceId(): string {
    let deviceId = sessionStorage.getItem(this.deviceIdKey);
    if (!deviceId) {
      deviceId = uuidv4();
      sessionStorage.setItem(this.deviceIdKey, deviceId);
    }

    return deviceId;
  }

  removeDeviceId(): void {
    sessionStorage.removeItem(this.deviceIdKey);
  }

  copyToLocalStorage(): void {
    localStorage.setItem(this.deviceIdKey, this.deviceId);
  }

  restoreToSessionStorage(): void {
    const deviceId = localStorage.getItem(this.deviceIdKey);
    if (deviceId) {
      sessionStorage.setItem(this.deviceIdKey, deviceId);
      this.removeFromLocalStorage();
    }
  }

  removeFromLocalStorage(): void {
    localStorage.removeItem(this.deviceIdKey);
  }
}
