import { NutritionOrderEnteralFormula } from '@hl7fhir';
import { CodeableConceptPipe, QuantityTypePipe } from '@hl7fhir/data-types';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import { NutritionOrderEnteralFormulaAdministrationViewModel } from './nutrition-order-enteral-formula-administration.viewmodel';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class NutritionOrderEnteralFormulaViewModel extends ElementViewModel<NutritionOrderEnteralFormula> {
  get baseFormulaType(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.baseFormulaType);
  }

  get baseFormulaProductName(): string | undefined {
    return this.element?.baseFormulaProductName;
  }

  get additiveType(): string | undefined {
    const nutritionOrderEnteralFormulaR = this.element as
      | r3.NutritionOrderEnteralFormula
      | r4.NutritionOrderEnteralFormula
      | r4b.NutritionOrderEnteralFormula;
    return new CodeableConceptPipe().transform(nutritionOrderEnteralFormulaR?.additiveType);
  }

  get additiveProductName(): string | undefined {
    const nutritionOrderEnteralFormulaR = this.element as
      | r3.NutritionOrderEnteralFormula
      | r4.NutritionOrderEnteralFormula
      | r4b.NutritionOrderEnteralFormula;
    return nutritionOrderEnteralFormulaR?.additiveProductName;
  }

  get administrationInstruction(): string | undefined {
    return this.element?.administrationInstruction;
  }

  get caloricDensity(): string | undefined {
    return new QuantityTypePipe().transform(this.element?.caloricDensity);
  }

  get maxVolumeToDeliver(): string | undefined {
    return new QuantityTypePipe().transform(this.element?.maxVolumeToDeliver);
  }

  get routeofAdministration(): string | undefined {
    const nutritionOrderEnteralFormulaR = this.element as
      | r3.NutritionOrderEnteralFormula
      | r4.NutritionOrderEnteralFormula
      | r4b.NutritionOrderEnteralFormula;
    return new CodeableConceptPipe().transform(nutritionOrderEnteralFormulaR?.routeofAdministration);
  }

  get administration(): NutritionOrderEnteralFormulaAdministrationViewModel[] | undefined {
    return this.element?.administration?.map(
      (administration) => new NutritionOrderEnteralFormulaAdministrationViewModel(administration, this.fhirVersion),
    );
  }
}
