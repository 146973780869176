import { Coverage } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectCoverageId(coverage: Coverage): string {
  // The only time that a resource does not have an id is when it is being submitted to the server using a create operation.
  // https://www.hl7.org/fhir/resource-definitions.html#Resource.id
  if (!coverage.id) throw new Error('The coverage is not created yet.');
  return coverage.id;
}

export function sortById(a: Coverage, b: Coverage): number {
  return selectCoverageId(a).localeCompare(selectCoverageId(b));
}

export type State = EntityState<Coverage>;

export const adapter: EntityAdapter<Coverage> = createEntityAdapter<Coverage>({
  selectId: selectCoverageId,
  sortComparer: sortById,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { coverages }) => {
    return adapter.upsertMany(coverages, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of coverage ids
export const selectCoverageIds = selectIds;

// select the dictionary of coverage entities
export const selectCoverageEntities = selectEntities;

// select the array of coverages
export const selectAllCoverages = selectAll;

// select the total coverage count
export const selectCoverageTotal = selectTotal;
