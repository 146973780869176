import { NutritionOrderOralDiet } from '@hl7fhir';
import { CodeableConceptPipe, TimingViewModel } from '@hl7fhir/data-types';
import { ElementViewModel } from '@hl7fhir/viewmodels';
import { NutritionOrderOralDietNutrientViewModel } from './nutrition-order-oral-diet-nutrient.viewmodel';
import { NutritionOrderOralDietTextureViewModel } from './nutrition-order-oral-diet-texture.viewmodel';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';

export class NutritionOrderOralDietViewModel extends ElementViewModel<NutritionOrderOralDiet> {
  get type(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.type);
  }

  get fluidConsistencyType(): string | undefined {
    return new CodeableConceptPipe().transform(this.element?.fluidConsistencyType);
  }

  get instruction(): string | undefined {
    return this.element?.instruction;
  }

  get schedule(): TimingViewModel[] | undefined {
    const nutritionOrderOralDietR = this.element as
      | r3.NutritionOrderOralDiet
      | r4.NutritionOrderOralDiet
      | r4b.NutritionOrderOralDiet;
    return nutritionOrderOralDietR.schedule?.map((timing) => new TimingViewModel(timing, this.fhirVersion));
  }

  get nutrient(): NutritionOrderOralDietNutrientViewModel[] | undefined {
    return this.element?.nutrient?.map(
      (nutrient) => new NutritionOrderOralDietNutrientViewModel(nutrient, this.fhirVersion),
    );
  }

  get texture(): NutritionOrderOralDietTextureViewModel[] | undefined {
    return this.element?.texture?.map(
      (texture) => new NutritionOrderOralDietTextureViewModel(texture, this.fhirVersion),
    );
  }
}
