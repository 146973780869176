import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as features from '../../features';
import { AppState } from '../app.reducer';

export const selectAppState = createFeatureSelector<AppState>(features.APP);

export const selectHasSessionTimedOut = createSelector(selectAppState, (state: AppState) => {
  return state?.hasSessionTimedOut;
});

export const selectOpenModal = (modal: string) => {
  return createSelector(selectAppState, (state: AppState) => {
    return state?.modal === modal;
  });
};

export const selectToast = createSelector(selectAppState, (state: AppState) => {
  return state?.toasts;
});

export const selectIsAuthenticated = createSelector(selectAppState, (state: AppState) => {
  return state.isAuthenticated;
});
