import { CodeableConcept, Extension } from '@hl7fhir';
import { CodeableConceptPipe, CodingsPipe, PeriodTypePipe } from '@hl7fhir/data-types';
import { ExtensionsPipe } from '@hl7fhir/extensibility';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { SelectsPipe } from '@shared';
import { ConsentExcept } from 'fhir/r3';
import { ConsentActorViewModel } from './consent-actor.viewmodel';
import { ConsentDataViewModel } from './consent-data.viewmodel';

export class ConsentExceptViewModel extends BackboneElementViewModel<ConsentExcept> {
  get type(): string | undefined {
    return this.element?.type;
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element.period);
  }

  get action(): string | undefined {
    return this.element?.action && new CodeableConceptPipe().transform(this.element.action);
  }

  get securityLabel(): string | undefined {
    return this.element?.securityLabel && new CodingsPipe().transform(this.element.securityLabel);
  }

  get purpose(): string | undefined {
    return this.element?.purpose && new CodingsPipe().transform(this.element.purpose);
  }

  get class(): string | undefined {
    return this.element?.class && new CodingsPipe().transform(this.element.class);
  }

  get code(): string | undefined {
    return this.element?.code && new CodingsPipe().transform(this.element.code);
  }

  get dataPeriod(): string | undefined {
    return this.element?.dataPeriod && new PeriodTypePipe().transform(this.element.dataPeriod);
  }

  get exceptType(): string | undefined {
    const extensions: Extension[] | undefined = new ExtensionsPipe().transform(
      this.element?.extension,
      StructureDefinition.Hl7.CONSENT.exceptType,
    );
    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectsPipe().transform(
      extensions,
      'valueCodeableConcept',
    );
    return valueCodeableConcepts && new CodeableConceptPipe().transform(valueCodeableConcepts);
  }

  get actor(): ConsentActorViewModel[] | undefined {
    return this.element?.actor && this.element.actor.map((item) => new ConsentActorViewModel(item, this.fhirVersion));
  }

  get data(): ConsentDataViewModel[] | undefined {
    return this.element?.data && this.element.data.map((item) => new ConsentDataViewModel(item, this.fhirVersion));
  }
}
