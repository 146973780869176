import { convertPeriodToSortable, convertToSortable } from '@globals';
import { MedicationStatement } from '@hl7fhir';
import { AnnotationViewModel, CodeableConceptPipe, getChoiceOfType, IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { MedicationStatementStatusPipe, MedicationStatementTakenPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { DosageViewModel } from '../dosage';

export class MedicationStatementViewModel extends DomainResourceViewModel<MedicationStatement> {
  private resourceR = this.resource as r3.MedicationStatement | r4.MedicationStatement | r4b.MedicationStatement;
  private resourceR3 = this.resource as r3.MedicationStatement;
  private resourceR5 = this.resource as r5.MedicationStatement;
  get identifier() {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get basedOn() {
    return getReferences(this.resourceR?.basedOn);
  }

  get category() {
    return new CodeableConceptPipe().transform(this.resource?.category);
  }

  get dateAsserted() {
    return this.resource?.dateAsserted;
  }

  get status() {
    return new MedicationStatementStatusPipe().transform(this.resource?.status);
  }

  get medication() {
    return getChoiceOfType({
      codeableConcept: this.resourceR.medicationCodeableConcept,
      reference: this.resourceR.medicationReference,
    });
  }

  get subject() {
    return getReference(this.resource?.subject);
  }

  get taken() {
    return new MedicationStatementTakenPipe().transform(this.resourceR3.taken);
  }

  get context() {
    return getReference(this.resourceR3.context);
  }

  get effective() {
    return getChoiceOfType({
      dateTime: this.resource?.effectiveDateTime,
      period: this.resource?.effectivePeriod,
      timing: this.resourceR5.effectiveTiming,
    });
  }

  get effectiveSortable(): Date | undefined {
    // TODO: When supporting R5 figure out how to sort timing.
    return (
      convertToSortable(this.resource?.effectiveDateTime) ?? convertPeriodToSortable(this.resource?.effectivePeriod)
    );
  }

  get informationSource() {
    return getReference(this.resourceR.informationSource);
  }

  get derivedFrom() {
    return getReferences(this.resource?.derivedFrom);
  }

  get dosage() {
    return this.resource?.dosage?.map((dosage) => new DosageViewModel(dosage, this.fhirVersion));
  }

  get note() {
    return this.resource?.note?.map((note) => new AnnotationViewModel(note, this.fhirVersion));
  }
  get partOf() {
    return getReferences(this.resource?.partOf);
  }

  get reasonCode() {
    return new CodeableConceptPipe().transform(this.resourceR.reasonCode);
  }

  get reasonNotTaken() {
    return new CodeableConceptPipe().transform(this.resourceR3.reasonNotTaken);
  }

  get reasonReference() {
    return getReferences(this.resourceR.reasonReference);
  }
}
