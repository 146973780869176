import { Composition } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectCompositionId(composition: Composition): string {
  if (!composition.id) throw new Error('The Composition is not created yet.');

  return composition.id;
}

export type State = EntityState<Composition>;

export const adapter: EntityAdapter<Composition> = createEntityAdapter<Composition>({
  selectId: selectCompositionId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { compositions }) => {
    return adapter.upsertMany(compositions, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompositionEntities = selectEntities;

export const selectAllCompositions = selectAll;
