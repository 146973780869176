import { Medication } from '@hl7fhir';
import { MedicationViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromMedication from './medication.reducer';

export const selectMedicationState = createFeatureSelector<fromMedication.State>(features.FHIR.medication);

export const selectMedicationEntities = createSelector(selectMedicationState, fromMedication.selectMedicationEntities);

export const selectAllMedications = createSelector(selectMedicationState, fromMedication.selectAllMedications);

export const selectMedicationById = createSelector(
  selectRouteParams,
  selectMedicationEntities,
  ({ id }, medications: Dictionary<Medication>) =>
    id && (medications[id] ?? medications[id.toLowerCase()] ?? medications[id.toUpperCase()]),
);

export const selectAllMedicationViewModels = createSelector(selectAllMedications, (medications) =>
  medications.map((medication) => new MedicationViewModel(medication)),
);

export const selectMedicationViewModelById = createSelector(
  selectMedicationById,
  (medication) => medication && new MedicationViewModel(medication),
);
