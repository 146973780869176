import { Condition } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectConditionId(condition: Condition): string {
  if (!condition.id) throw new Error('The condition is not created yet.');
  return condition.id;
}

export type State = EntityState<Condition>;

export const adapter: EntityAdapter<Condition> = createEntityAdapter<Condition>({
  selectId: selectConditionId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { conditions }) => {
    return adapter.upsertMany(conditions, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectConditionEntities = selectEntities;

export const selectAllConditions = selectAll;
