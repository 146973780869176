import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const NHGTable14v7ContactType: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.103.11.3--20110902000000',
  systems: {
    'http://hl7.org/fhir/v3/NullFlavor': {
      UNK: {
        display: 'Onbekend',
      },
      OTH: {
        display: 'Anders',
      },
    },
    'https://referentiemodel.nhg.org/tabellen/nhg-tabel-14-contactwijze': {
      '01': {
        display: 'visite',
      },
      '02': {
        display: 'nacht/dienst visite',
      },
      '03': {
        display: 'consult',
      },
      '04': {
        display: 'nacht/dienst consult',
      },
      '05': {
        display: 'telefonisch contact',
      },
      '06': {
        display: 'nacht/dienst telefonisch consult',
      },
      '08': {
        display: 'postverwerking',
      },
      '09': {
        display: 'overleg',
      },
      '11': {
        display: 'notitie/memo',
      },
      '12': {
        display: 'e-consult',
      },
    },
  },
};
