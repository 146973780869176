import { Pipe, PipeTransform } from '@angular/core';
import { formatLocaleDate } from '@globals';
import { Period } from '@hl7fhir';

@Pipe({
  name: 'period',
})
export class PeriodTypePipe implements PipeTransform {
  transform(period: Period[] | Period | undefined): string | undefined {
    if (!period) {
      return '';
    }

    if (Array.isArray(period)) {
      return period.map((p) => this.formatPeriod(p)).join(', ');
    } else {
      return this.formatPeriod(period);
    }
  }

  private formatPeriod(period: Period): string {
    let result = '';

    if (period.start) {
      result = `${$localize`:@@fhir.Period.start:From`} ${formatLocaleDate(period.start)} `;
    }

    if (period.end) {
      if (period.start) {
        result += `${$localize`:@@fhir.Period.lowerTextOfEnd:to`} ${formatLocaleDate(period.end)}`;
      } else {
        result += `${$localize`:@@fhir.Period.upperTextOfEnd:To`} ${formatLocaleDate(period.end)}`;
      }
    }

    if (period.start === period.end) {
      result = formatLocaleDate(period.start);
    }

    return result.trimEnd();
  }
}
