import { createReducer, on } from '@ngrx/store';
import { PAGE_ACTIONS } from './page.actions';

export interface PageState {
  header: string;
}

export const initialState: PageState = {
  header: '',
};

export const reducer = createReducer(
  initialState,
  on(PAGE_ACTIONS.setPageHeader, (state, action): PageState => {
    return { ...state, header: action.header };
  }),
);
