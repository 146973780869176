import { ErrorCodes } from '@globals';
import { createReducer, on } from '@ngrx/store';
import { APP_ACTIONS, ERROR_UI_ACTIONS, TOAST_ACTIONS } from './actions/app.actions';

export interface AppState {
  isAuthenticated: boolean;
  error: {
    data: any;
  };
  showingErrorModal: boolean;
  securityPromptModal: boolean;
  hasSessionTimedOut: boolean;
  modal: string | null;
  toasts: ToastState[];
}

export interface ToastState {
  message: string;
  typeMessage: 'error' | 'info';
}

export const initialState: AppState = {
  isAuthenticated: false,
  error: {
    data: null,
  },
  showingErrorModal: false,
  securityPromptModal: false,
  hasSessionTimedOut: false,
  modal: null,
  toasts: [],
};

export const reducer = createReducer(
  initialState,
  on(APP_ACTIONS.authenticated, (state): AppState => {
    return { ...state, isAuthenticated: true };
  }),
  on(APP_ACTIONS.timeoutLogout, (state): AppState => {
    return { ...state, isAuthenticated: false };
  }),
  on(APP_ACTIONS.unauthenticated, (state): AppState => {
    return { ...state, isAuthenticated: false };
  }),
  on(ERROR_UI_ACTIONS.showErrorMessage, (state, action): AppState => {
    return {
      ...state,
      error: {
        ...state.error,
        data: action.code === ErrorCodes.userCancel ? undefined : action.code,
      },
      showingErrorModal: true,
    };
  }),
  on(ERROR_UI_ACTIONS.resetErrorMessage, (_state): AppState => {
    return { ..._state, showingErrorModal: false, error: { data: null } };
  }),
  on(APP_ACTIONS.showSecurityPromptModal, (_state, action): AppState => {
    return { ..._state, securityPromptModal: action.show };
  }),
  on(APP_ACTIONS.timeoutLogout, (): AppState => {
    return { ...initialState, hasSessionTimedOut: true };
  }),
  on(TOAST_ACTIONS.showToast, (state, action): AppState => {
    return { ...state, toasts: [...state.toasts, action] };
  }),
  on(TOAST_ACTIONS.hideToast, (state, action): AppState => {
    return { ...state, toasts: state.toasts.filter((t) => t.message !== action.message) };
  }),
);
