import * as fhir from '@hl7fhir';
import { FhirVersion } from '../foundation/version';

export class ElementViewModel<T extends fhir.Element> {
  constructor(
    readonly element: T | undefined,
    readonly fhirVersion: FhirVersion | undefined,
  ) {}

  get id(): string | undefined {
    return this.element?.id;
  }

  protected hasVersion(...versions: FhirVersion[]): boolean {
    if (!this.fhirVersion) {
      return false;
    }
    return versions.includes(this.fhirVersion);
  }

  protected isR3(): boolean {
    return this.hasVersion('R3');
  }

  protected isR5(): boolean {
    return this.hasVersion('R5');
  }
}

export class BackboneElementViewModel<T extends fhir.Element> extends ElementViewModel<T> {}
