import { DocumentManifest } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectDocumentManifestId(documentManifest: DocumentManifest): string {
  if (!documentManifest.id) throw new Error('The DocumentManifest is not created yet.');

  return documentManifest.id;
}

export type State = EntityState<DocumentManifest>;

export const adapter: EntityAdapter<DocumentManifest> = createEntityAdapter<DocumentManifest>({
  selectId: selectDocumentManifestId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { documentManifests }) => {
    return adapter.upsertMany(documentManifests, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDocumentManifestEntities = selectEntities;

export const selectAllDocumentManifests = selectAll;
