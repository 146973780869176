<app-entry>
  <label data-cy="contact-point-label"
         class="fw-semibold"
         [ngSwitch]="contactPoint?.system">
    <span *ngSwitchCase="'phone'"
          i18n="@@fhir.contact-point-system.phone">Phone</span>
    <span *ngSwitchCase="'fax'"
          i18n="@@fhir.contact-point-system.fax">Fax</span>
    <span *ngSwitchCase="'email'"
          i18n="@@fhir.contact-point-system.email">Email</span>
    <span *ngSwitchCase="'pager'"
          i18n="@@fhir.contact-point-system.pager">Pager</span>
    <span *ngSwitchCase="'url'"
          i18n="@@fhir.contact-point-system.url">URL</span>
    <span *ngSwitchCase="'sms'"
          i18n="@@fhir.contact-point-system.sms">SMS</span>
    <span *ngSwitchCase="'other'"
          i18n="@@fhir.contact-point-system.other">Other</span>
    <span *ngSwitchDefault></span>
  </label>
  <span data-cy="contact-point-value">{{ contactPoint?.value }}</span>
</app-entry>
<app-entry>
  <label class="fw-semibold"
         i18n="@@fhir.ContactPoint.use">Use</label>
  <span data-cy="contact-point-use">{{ contactPoint | contactPointUse }}</span>
</app-entry>

<!-- Extension Type -->
<app-entry
           *ngIf="contactPoint?.extension | extension:structureDefinition.Nictiz.CONTACT_INFORMATION.telecomType as extension">
  <label class="fw-semibold"
         i18n="@@fhir.ContactPoint.type">Type</label>
  <span data-cy="contact-point-type">{{
    extension.valueCodeableConcept | codeableConcept: [telecomTypeCodelijst]
    }}</span>
</app-entry>