import { EpisodeOfCare } from '@hl7fhir';
import { EpisodeOfCareViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromEpisodeOfCare from './episode-of-care.reducer';

export const selectEpisodeOfCareState = createFeatureSelector<fromEpisodeOfCare.State>(features.FHIR.episodeOfCare);

export const selectEpisodeOfCareEntities = createSelector(
  selectEpisodeOfCareState,
  fromEpisodeOfCare.selectEpisodeOfCareEntities,
);

export const selectAllEpisodesOfCare = createSelector(
  selectEpisodeOfCareState,
  fromEpisodeOfCare.selectAllEpisodesOfCare,
);

export const selectEpisodeOfCareById = createSelector(
  selectRouteParams,
  selectEpisodeOfCareEntities,
  ({ id }, episodeOfCares: Dictionary<EpisodeOfCare>) =>
    id && (episodeOfCares[id] ?? episodeOfCares[id.toLowerCase()] ?? episodeOfCares[id.toUpperCase()]),
);

export const selectAllEpisodeOfCareViewModels = createSelector(selectAllEpisodesOfCare, (episodesOfCare) =>
  episodesOfCare.map((episodeOfCare) => new EpisodeOfCareViewModel(episodeOfCare)),
);

export const selectEpisodeOfCareViewModelById = createSelector(
  selectEpisodeOfCareById,
  (episodeOfCare) => episodeOfCare && new EpisodeOfCareViewModel(episodeOfCare),
);
