import { AccountSyncStatusEntryPartial, ObjectTypeError } from '@digi.me/models';
import { AccountStatus } from './account-status.model';
import { AccountWarnings } from './account-warnings.model';
import { ResourceTypes } from './resource-types.model';

// String to determine if the error message contains the unsupported record type
const UNSUPPORTED_TXT = "FHIR operation 'Search' not supported for resource type";

export function getAccountsWithWarningsByObjectTypeError(
  aws: AccountStatus[],
  objectType: number[],
): AccountWarnings[] {
  return aws
    .map((aws) => getAccountWithWarnings(aws, objectType))
    .filter((aww): aww is AccountWarnings => aww !== undefined);
}

export function getAccountWithWarnings(aws: AccountStatus, objectType?: ResourceTypes[]): AccountWarnings | undefined {
  // Get account name
  const providerName = aws.account?.serviceProviderName;

  // Partial sync errors are errors that are not related to unsupported record types
  const partialSyncErrors = getPartialSyncErrors(aws, objectType);

  // Get unsupported resource types names
  const unsupportedResourceTypes = getUnsupportedResourceTypesErrors(aws, objectType);

  if ((partialSyncErrors?.length ?? 0) > 0 || (unsupportedResourceTypes?.length ?? 0) > 0) {
    return {
      providerName,
      unsupportedResourceTypes,
      partialSyncErrors,
    } as AccountWarnings;
  }

  return undefined;
}

/**
 * Retrieves the partial synchronization errors for a resource type.
 * @param aws - The AccountStatus object representing the account and the account status.
 * @param objectTypes - The optional object types to filter the errors by.
 * @returns An array of strings representing the partial synchronization errors, or undefined .
 */
export function getPartialSyncErrors(aws: AccountStatus, objectTypes?: number[]): string[] | undefined {
  const objectTypeErrors = (aws.status as AccountSyncStatusEntryPartial)?.error?.objectTypeErrors;

  if (!objectTypeErrors) {
    return undefined;
  }

  const partialSyncErrors = objectTypeErrors.filter((error: ObjectTypeError) =>
    objectTypes
      ? objectTypes.includes(error.objectType) && !error.error.message.includes(UNSUPPORTED_TXT)
      : !error.error.message.includes(UNSUPPORTED_TXT),
  );

  return partialSyncErrors.map((error: ObjectTypeError) => ResourceTypes[error.objectType]);
}

/**
 * Retrieves the unsupported resource type errors for a resource type.
 *
 * @param aws - The AccountStatus object representing the account and the account status.
 * @param objectTypes - The optional object types to filter the errors by.
 * @returns An array of unsupported resource type errors, or undefined.
 */
export function getUnsupportedResourceTypesErrors(
  aws: AccountStatus,
  objectTypes?: number[] | undefined,
): string[] | undefined {
  const objectTypeErrors = (aws.status as AccountSyncStatusEntryPartial)?.error?.objectTypeErrors;

  if (!objectTypeErrors) {
    return undefined;
  }

  const unsupportedTypeErrors = objectTypeErrors.filter((error: ObjectTypeError) =>
    objectTypes
      ? objectTypes.includes(error.objectType) && error.error.message.includes(UNSUPPORTED_TXT)
      : error.error.message.includes(UNSUPPORTED_TXT),
  );

  return unsupportedTypeErrors.map((error: ObjectTypeError) => ResourceTypes[error.objectType]);
}
