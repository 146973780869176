import { DeviceUseStatement } from '@hl7fhir';
import { DeviceUseStatementViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromDeviceUseStatement from './device-use-statement.reducer';

export const selectDeviceUseStatementState = createFeatureSelector<fromDeviceUseStatement.State>(
  features.FHIR.deviceUseStatements,
);

export const selectDeviceUseStatementEntities = createSelector(
  selectDeviceUseStatementState,
  fromDeviceUseStatement.selectDeviceUseStatementEntities,
);

export const selectAllDeviceUseStatements = createSelector(
  selectDeviceUseStatementState,
  fromDeviceUseStatement.selectAllDeviceUseStatements,
);

export const selectDeviceUseStatementById = createSelector(
  selectRouteParams,
  selectDeviceUseStatementEntities,
  ({ id }, deviceUseStatement: Dictionary<DeviceUseStatement>) =>
    id && (deviceUseStatement[id] ?? deviceUseStatement[id.toLowerCase()] ?? deviceUseStatement[id.toUpperCase()]),
);

export const selectAllDeviceUseStatementViewModels = createSelector(
  selectAllDeviceUseStatements,
  (deviceUseStatements) => deviceUseStatements.map((statements) => new DeviceUseStatementViewModel(statements)),
);

export const selectDeviceUseStatementViewModelById = createSelector(
  selectDeviceUseStatementById,
  (deviceUseStatement) => deviceUseStatement && new DeviceUseStatementViewModel(deviceUseStatement),
);
