import { convertPeriodToSortable, formatLocaleDate } from '@globals';
import { DeviceUseStatement } from '@hl7fhir';
import {
  AnnotationViewModel,
  CodeableConceptPipe,
  getChoiceOfType,
  IdentifierViewModel,
  PeriodTypePipe,
} from '@hl7fhir/data-types';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { DeviceUseStatementStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';

export class DeviceUseStatementViewModel extends DomainResourceViewModel<DeviceUseStatement> {
  get identifier(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  /**
   * A Nictiz extension
   */
  get healthcareProvider() {
    return new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.MEDICAL_DEVICE.organization,
    )?.valueReference?.display; // TODO: Reference
  }

  /**
   * A Nictiz extension
   */
  get healthcareProfessional() {
    return new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.MEDICAL_DEVICE.practitioner,
    )?.valueReference?.display; // TODO: Reference
  }

  /**
   * A Nictiz extension
   */
  get reason() {
    return new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.DEVICE_USE_STATEMENT.reasonReference,
    )?.valueReference?.display; // TODO: Reference
  }

  get status() {
    return new DeviceUseStatementStatusPipe().transform(this.resource?.status);
  }

  get subject() {
    return this.resource?.subject.display; // TODO: Reference
  }

  get whenUsed() {
    const resourceR3 = this.resource as r3.DeviceUseStatement;
    return new PeriodTypePipe().transform(resourceR3?.whenUsed);
  }

  get whenUsedSortable() {
    const resourceR3 = this.resource as r3.DeviceUseStatement;
    return convertPeriodToSortable(resourceR3?.whenUsed);
  }

  get timing() {
    return getChoiceOfType({
      timing: this.resource?.timingTiming,
      period: this.resource?.timingPeriod,
      dateTime: this.resource?.timingDateTime,
    });
  }

  get recordedOn() {
    return formatLocaleDate(this.resource?.recordedOn);
  }

  get source() {
    return this.resource?.source?.display; // TODO: Reference
  }

  get device() {
    return this.resource?.device.display; // TODO: Reference
  }

  get indication() {
    const resourceR3 = this.resource as r3.DeviceUseStatement;
    return new CodeableConceptPipe().transform(resourceR3?.indication);
  }

  get bodySite() {
    return new CodeableConceptPipe().transform(this.resource?.bodySite);
  }

  get note() {
    return this.resource?.note?.map((n) => new AnnotationViewModel(n, this.fhirVersion));
  }
}
