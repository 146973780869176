import { Pipe, PipeTransform } from '@angular/core';
import { ContactPoint } from '@hl7fhir';

@Pipe({
  name: 'contactPointSystemWhere',
})
export class ContactPointSystemWherePipe implements PipeTransform {
  transform(
    value: ContactPoint[] | undefined | null,
    system: ('phone' | 'fax' | 'email' | 'pager' | 'url' | 'sms' | 'other') | undefined,
  ): ContactPoint[] {
    // Return empty array when the input array is undefined, null or empty
    if (!value?.length) return [];

    return value.filter((contact) => contact.system === system);
  }
}
