import { DocumentManifest, DocumentReference } from '@hl7fhir';
import { DocumentViewViewModel } from '@hl7fhir/resource-types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as r3 from 'fhir/r3';
import { selectAllDocumentManifests } from '../document-manifest';
import { selectAllDocumentReferences } from '../document-reference';
import { DigiMeSortState } from './document-view.reducer';

export const selectDigiMeState = createFeatureSelector<DigiMeSortState>('sort');

export const selectSortState = createSelector(selectDigiMeState, (state) => state);

export const selectAllDocumentsViewModels = createSelector(
  selectAllDocumentManifests,
  selectAllDocumentReferences,
  selectSortState,
  (
    documentManifests: DocumentManifest[],
    documentReferences: DocumentReference[],
    sortState: DigiMeSortState,
  ): DocumentViewViewModel[] => {
    // Create a new array to store the sorted documents
    let result: DocumentViewViewModel[] = [];

    // Create a new array to store all the documents
    const allDocuments: DocumentViewViewModel[] = [];

    // Create a new array to store the documents that should be ignored
    const ignoreDocuments: DocumentViewViewModel[] = [];

    // Get the sortDirection and sortBy from the sortState
    const sortDirection = sortState?.sortDirection === 'asc' ? 1 : -1;
    const sortBy = sortState?.sortBy;

    // Add all the documentReferences and documentManifests to the allDocuments array
    for (const ref of documentReferences) {
      allDocuments.push(new DocumentViewViewModel(ref));
    }

    for (const manifest of documentManifests) {
      allDocuments.push(new DocumentViewViewModel(manifest));
    }

    // Sort the allDocuments based on the sortBy and sortDirection
    if (sortBy === 'Date') {
      allDocuments.sort((a, b) => sortDirection * (a.dateSortable?.getTime() ?? 0) - (b.dateSortable?.getTime() ?? 0));
    } else if (sortBy === 'Details') {
      allDocuments.sort((a, b) => sortDirection * (a.title ?? '').localeCompare(b.title ?? ''));
    }

    allDocuments.forEach((documentView) => {
      // If the documentView is in the ignoreDocuments array, skip it
      if (ignoreDocuments.includes(documentView)) {
        return;
      }
      result.push(documentView);

      if (documentView?.resource?.resourceType === 'DocumentManifest') {
        const manifestR3 = documentView?.resource as r3.DocumentManifest;

        // Filter a content that doesn't have a reference, create immutable array
        const content = manifestR3.content.filter((content) => content.pReference !== undefined);

        // Sort the content based on the sortBy and sortDirection
        const sortedContent = content.sort(sortContent(allDocuments, sortBy, sortDirection));

        // Add the sorted content to the result
        sortedContent.forEach((content, index) => {
          const relatedRef = allDocuments.find((ref) => {
            return content.pReference?.reference === ref?.resource?.resourceType + '/' + ref?.resource?.id;
          });
          if (relatedRef) {
            // Remove the relatedRef from the result array, if relatedRef is in the result array
            result = result.filter((doc) => doc !== relatedRef);

            // Add the relatedRef to the ignoreDocuments array, if relatedRef isn't in the result array
            ignoreDocuments.push(relatedRef);

            // Add the relatedRef to the result array how slave document
            result.push(
              new DocumentViewViewModel(
                relatedRef.resource as DocumentReference,
                documentView,
                index === manifestR3.content.length - 1,
              ),
            );
          }
        });
      }
    });

    return result;
  },
);

/**
 * Sort the content based on the sortBy and sortDirection
 * @param allDocuments
 * @param sortBy
 * @param sortDirection
 * @returns a function that compares two DocumentManifestContent
 **/
function sortContent(
  allDocuments: DocumentViewViewModel[],
  sortBy: string | undefined,
  sortDirection: number,
): ((a: r3.DocumentManifestContent, b: r3.DocumentManifestContent) => number) | undefined {
  return (a, b) => {
    const aReference = allDocuments.find((ref) => {
      return a.pReference?.reference === ref?.resource?.resourceType + '/' + ref?.resource?.id;
    });
    const bReference = allDocuments.find((ref) => {
      return b.pReference?.reference === ref?.resource?.resourceType + '/' + ref?.resource?.id;
    });

    if (aReference === undefined || bReference === undefined) {
      return 0;
    }

    if (sortBy === 'Date') {
      return sortDirection * (new Date(aReference?.date ?? '').getTime() - new Date(bReference?.date ?? '').getTime());
    } else if (sortBy === 'Details') {
      return sortDirection * (aReference?.title ?? '').localeCompare(bReference?.title ?? '');
    }

    return 0;
  };
}
