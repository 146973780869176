import { Device } from '@hl7fhir';
import { DeviceViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromDevice from './device.reducer';

export const selectDeviceState = createFeatureSelector<fromDevice.State>(features.FHIR.devices);

export const selectDeviceEntities = createSelector(selectDeviceState, fromDevice.selectDeviceEntities);

export const selectAllDevices = createSelector(selectDeviceState, fromDevice.selectAllDevices);

export const selectDeviceById = createSelector(
  selectRouteParams,
  selectDeviceEntities,
  ({ id }, devices: Dictionary<Device>) =>
    id && (devices[id] ?? devices[id.toLowerCase()] ?? devices[id.toUpperCase()]),
);

export const selectAllDeviceViewModels = createSelector(selectAllDevices, (devices) =>
  devices.map((device) => new DeviceViewModel(device)),
);

export const selectDeviceViewModelById = createSelector(
  selectDeviceById,
  (device) => device && new DeviceViewModel(device),
);
