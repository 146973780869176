import { MedicationDispense } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { MedicationDispenseViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromMedicationDispense from './medication-dispense.reducer';

export const selectMedicationDispenseState = createFeatureSelector<fromMedicationDispense.State>(
  features.FHIR.medicationDispenses,
);

export const selectMedicationDispenseEntities = createSelector(
  selectMedicationDispenseState,
  fromMedicationDispense.selectMedicationDispenseEntities,
);

export const selectAllMedicationDispenses = createSelector(
  selectMedicationDispenseState,
  fromMedicationDispense.selectAllMedicationDispenses,
);

export const selectMedicationDispenseById = createSelector(
  selectRouteParams,
  selectMedicationDispenseEntities,
  ({ id }, medicationDispenses: Dictionary<MedicationDispense>) =>
    id && (medicationDispenses[id] ?? medicationDispenses[id.toLowerCase()] ?? medicationDispenses[id.toUpperCase()]),
);

export const selectAdministrationAgreements = createSelector(selectAllMedicationDispenses, (medicationDispenses) =>
  medicationDispenses.filter((medicationDispense) =>
    medicationDispense?.category?.coding?.some(
      (coding) => coding?.system === CodeSystems.SNOMED && ['422037009'].includes(coding.code ?? ''),
    ),
  ),
);

export const selectAllAdministrationAgreementsViewModels = createSelector(
  selectAdministrationAgreements,
  (adminstrationAgreements) =>
    adminstrationAgreements.map((administrationAgreement) => new MedicationDispenseViewModel(administrationAgreement)),
);

export const selectAdministrationAgreementViewModelById = createSelector(
  selectMedicationDispenseById,
  (medicationRequest) => medicationRequest && new MedicationDispenseViewModel(medicationRequest),
);
