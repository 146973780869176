import { formatLocaleDate } from '@globals';
import { MedicationPackageBatch } from '@hl7fhir';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';

export class MedicationPackageBatchViewModel extends BackboneElementViewModel<MedicationPackageBatch> {
  get lotNumber(): string | undefined {
    return this.element?.lotNumber;
  }

  get expirationDate(): string | undefined {
    return this.element?.expirationDate && formatLocaleDate(this.element.expirationDate);
  }
}
