import { ImagingStudy } from '@hl7fhir';
import { ImagingStudyViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromImagingStudy from './imaging-study.reducer';

export const selectImagingStudyState = createFeatureSelector<fromImagingStudy.State>(features.FHIR.imagingStudies);

export const selectImagingStudyEntities = createSelector(
  selectImagingStudyState,
  fromImagingStudy.selectImagingStudyEntities,
);

export const selectAllImagingStudies = createSelector(
  selectImagingStudyState,
  fromImagingStudy.selectAllImagingStudies,
);

export const selectImagingStudyById = createSelector(
  selectRouteParams,
  selectImagingStudyEntities,
  ({ id }, imagingStudies: Dictionary<ImagingStudy>) =>
    id && (imagingStudies[id] ?? imagingStudies[id.toLowerCase()] ?? imagingStudies[id.toUpperCase()]),
);

export const selectAllImagingStudyViewModels = createSelector(selectAllImagingStudies, (dataSource: ImagingStudy[]) =>
  dataSource.map((item) => new ImagingStudyViewModel(item)),
);

export const selectImagingStudyViewModelById = createSelector(
  selectImagingStudyById,
  (entity: ImagingStudy | undefined) => entity && new ImagingStudyViewModel(entity),
);
