import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { Medication } from '@hl7fhir';
import { CodeableConceptPipe, YesNoPipe } from '@hl7fhir/data-types';
import { MedicationStatusPipe } from '@hl7fhir/value-sets';
import * as r3 from 'fhir/r3';
import { MedicationIngredientViewModel } from './medication-ingredient.viewmodel';
import { MedicationPackageViewModel } from './medication-package.viewmodel';

export class MedicationViewModel extends DomainResourceViewModel<Medication> {
  private resourceR3 = this.resource as r3.Medication;

  get code() {
    return new CodeableConceptPipe().transform(this.resource?.code, undefined, false, true);
  }

  get status() {
    return new MedicationStatusPipe().transform(this.resource?.status);
  }

  get isBrand() {
    return new YesNoPipe().transform(this.resourceR3.isBrand);
  }

  get isOverTheCounter() {
    return new YesNoPipe().transform(this.resourceR3.isOverTheCounter);
  }

  get manufacturer() {
    return this.resourceR3.manufacturer?.display; // TODO: Reference
  }

  get form() {
    return new CodeableConceptPipe().transform(this.resourceR3.form);
  }

  get ingredient() {
    return this.resource?.ingredient?.map(
      (ingredient) => new MedicationIngredientViewModel(ingredient, this.fhirVersion),
    );
  }

  get package() {
    return new MedicationPackageViewModel(this.resourceR3?.package, this.fhirVersion);
  }
}
