import { Pipe, PipeTransform } from '@angular/core';
import { Quantity } from '@hl7fhir';

@Pipe({
  name: 'quantities',
})
export class QuantitiesTypePipe implements PipeTransform {
  transform(quantities: (Quantity | undefined)[] | undefined): string {
    const separator = '/';

    if (!quantities || quantities.length === 0) {
      return '';
    }

    const formattedQuantities = quantities.map((quantity) => {
      if (!quantity || quantity.value === undefined || quantity.value === null) {
        return '-';
      }
      return `${quantity.value}`;
    });

    const result = formattedQuantities.join(separator);

    const unit = quantities.find((q) => q?.unit)?.unit || '';

    return unit ? `${result} ${unit}` : result;
  }
}
