import { ErrorOrigin } from './digi-me-event-enums.model';
import { MixpanelEventProperties } from './mixpanel-event-properties.model';

/**
 * Class to represent error event properties
 */
export class ErrorEventProperties extends MixpanelEventProperties {
  /**
   * Best-estimate origin of the error
   */
  error_origin?: ErrorOrigin;

  /**
   * The name property of the JS Error object
   */
  error_name?: string;

  /**
   * The stack property of the JS Error object
   */
  error_stack_trace?: string;

  /**
   * The message property of the JS Error object
   */
  error_message?: string;

  /**
   * The URL of the page where the error occurred
   */
  error_api_code?: string;

  /**
   * The URL of the page where the error occurred
   */
  error_api_message?: string;
}
