import { createSelector } from '@ngrx/store';
import { LoadingState } from '@store';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export const selectUserAd = createSelector(selectDigiMeState, (state: DigiMeState) => state.userAd.data ?? null);

export const selectUserAdIsInitializingOrLoading = createSelector(
  selectDigiMeState,
  (state: DigiMeState) =>
    state.userAd.callState === LoadingState.init || state.userAd.callState === LoadingState.loading,
);
