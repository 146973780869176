import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const ContactTypeCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.15.1.1--20171231000000',
  systems: {
    'http://hl7.org/fhir/v3/NullFlavor': {
      OTH: {
        'nl-NL': 'Anders',
        display: 'Other',
      },
    },
    'http://hl7.org/fhir/v3/ActCode': {
      AMB: {
        'nl-NL': 'Poliklinisch',
        display: 'Ambulatory',
      },
      EMER: {
        'nl-NL': 'SEH',
        display: 'Emergency',
      },
      FLD: {
        'nl-NL': 'Op lokatie',
        display: 'Field',
      },
      HH: {
        'nl-NL': 'Thuis',
        display: 'Home',
      },
      IMP: {
        'nl-NL': 'Klinisch',
        display: 'Inpatient',
      },
      SS: {
        'nl-NL': 'Dagopname',
        display: 'Short Stay',
      },
      VR: {
        'nl-NL': 'Virtueel',
        display: 'Virtual',
      },
    },
  },
};
