import { formatLocaleDate } from '@globals';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import { ImmunizationEducation } from 'fhir/r4';

export class ImmunizationEducationViewModel extends BackboneElementViewModel<ImmunizationEducation> {
  get documentType(): string | undefined {
    return this.element?.documentType;
  }

  get reference(): string | undefined {
    return this.element?.reference;
  }

  get publicationDate(): string | undefined {
    return this.element?.publicationDate && formatLocaleDate(this.element.publicationDate);
  }

  get presentationDate(): string | undefined {
    return this.element?.presentationDate && formatLocaleDate(this.element.presentationDate);
  }
}
