import { createSelector } from '@ngrx/store';
import { LoadingState } from '@store';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export const selectIsResetLoading = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.reset.callState === LoadingState.loading,
);

export const selectShowResetWarning = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.reset.showWarning,
);
