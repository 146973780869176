import { formatLocaleDate } from '@globals';
import { ImmunizationRecommendation } from '@hl7fhir';
import { IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { ImmunizationRecommendationRecommendationViewModel } from './immunization-recommendation-recommendation.viewmodel';

export class ImmunizationRecommendationViewModel extends DomainResourceViewModel<ImmunizationRecommendation> {
  //R4, R4b, R5 only
  get date(): string | undefined {
    const imRec = this.resource as
      | r4.ImmunizationRecommendation
      | r4b.ImmunizationRecommendation
      | r5.ImmunizationRecommendation;

    return imRec.date && formatLocaleDate(imRec.date);
  }

  //R4, R4b, R5 only
  get sortDate(): string | undefined {
    const imRec = this.resource as
      | r4.ImmunizationRecommendation
      | r4b.ImmunizationRecommendation
      | r5.ImmunizationRecommendation;

    return imRec.date;
  }

  //R4, R4b, R5 only
  get authority(): string | undefined {
    const imRec = this.resource as
      | r4.ImmunizationRecommendation
      | r4b.ImmunizationRecommendation
      | r5.ImmunizationRecommendation;

    return imRec.authority && getReference(imRec.authority);
  }

  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get patient(): string | undefined {
    return this.resource?.patient && getReference(this.resource.patient);
  }

  get recommendation(): ImmunizationRecommendationRecommendationViewModel[] | undefined {
    return (
      this.resource?.recommendation &&
      this.resource.recommendation?.map(
        (item) => new ImmunizationRecommendationRecommendationViewModel(item, this.fhirVersion),
      )
    );
  }
}
