import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorCodes } from '@globals';
import { MixpanelService } from '@logging';
import { Store } from '@ngrx/store';
import { APP_ACTIONS, ERROR_UI_ACTIONS } from '@store/app/actions';
import { USER_ACTIONS } from '@store/user';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private readonly mixpanelService: MixpanelService,
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  // TODO Create utils class and move the function
  static extractAccountId(error: any): string | undefined {
    if (!error) {
      return undefined;
    }
    const keys = Object.keys(error);
    if (keys.length === 0) {
      return undefined;
    }
    return keys[0] as string;
  }

  handleError(response: any, message: string): void {
    const exception: Error = new Error(message);
    exception.name = response instanceof HttpErrorResponse ? response.name : 'ErrorResponse';

    const props =
      response instanceof HttpErrorResponse
        ? this.getHttpErrorResponseDetails(response)
        : {
            ...(response && { response }),
            ...(response?.status && { httpStatus: response?.status }),
          };

    // Do not log expected Http status 401, 403, and 429 to MixPanel
    switch (props?.httpStatus) {
      case 401:
      case 403:
      case 429:
        break;
      default:
        this.mixpanelService.trackApiException(exception, props?.httpStatus, props?.message).subscribe();
    }

    this.handleHttpStatus(props);
  }

  private handleHttpStatus(props: any): void {
    switch (props?.httpStatus) {
      case 401:
        this.store.dispatch(USER_ACTIONS.signOut());
        this.store.dispatch(APP_ACTIONS.authenticationStarted({ prompt: 'login' }));
        break;
      case 403:
        // No sources onboarded yet, stay on the same page
        break;
      case 429:
        // Swallow for now.
        break;
      default:
        this.store.dispatch(ERROR_UI_ACTIONS.showErrorMessage({ code: ErrorCodes.serverError }));
    }
  }

  private getHttpErrorResponseDetails(errorResponse: HttpErrorResponse) {
    if (!errorResponse) {
      return null;
    }

    const error = errorResponse.error?.error ?? errorResponse;
    const messageEntries = {
      ...(errorResponse?.status && { httpStatus: errorResponse?.status }),
      ...(error?.message && { message: error?.message }),
      ...(error?.code && { errorCode: error?.code }),
      ...(error?.innerError?.code && { innerErrorCode: error?.innerError?.code }),
    };

    return messageEntries;
  }
}
