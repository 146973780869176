import { convertPeriodToSortable, convertToSortable, LoincCodes, Nhg45, SnomedCodes } from '@globals';
import { CodeableConcept, Coding, Extension, Observation, Quantity, Timing } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import {
  AnnotationViewModel,
  CodeableConceptPipe,
  getChoiceOfType,
  IdentifierViewModel,
  QuantitiesTypePipe,
  QuantityTypePipe,
  YesNoPipe,
} from '@hl7fhir/data-types';
import { ExtensionPipe, ExtensionsPipe } from '@hl7fhir/extensibility';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { ObservationStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { GlucoseMeetmomentenLOINCCodelijst } from '@hl7nl-fhir/codesystems';
import { HARTSLAGREGELMATIGHEID_CODELIJST } from '@hl7nl-fhir/value-sets';
import { SelectsPipe } from '@shared';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { ObservationComponentViewModel } from './observation-component.viewmodel';
import { ObservationReferenceViewModel } from './observation-reference.viewmodel';

export class ObservationViewModel extends DomainResourceViewModel<Observation> {
  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get effective(): string | undefined {
    return getChoiceOfType({
      dateTime: this.resource?.effectiveDateTime,
      period: this.resource?.effectivePeriod,
      timing: this.effectiveTiming,
      instant: this.effectiveInstant,
    });
  }

  get effectiveSortable(): Date | undefined {
    return (
      convertToSortable(this.resource?.effectiveDateTime) ?? convertPeriodToSortable(this.resource?.effectivePeriod)
    );
    // TODO Support sorting for timing and instant
  }

  get basedOn(): string | undefined {
    return getReferences(this.resource?.basedOn);
  }

  get partOf(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReferences(observationR?.partOf);
  }

  get status(): string | undefined {
    return new ObservationStatusPipe().transform(this.resource?.status);
  }

  get category(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.category);
  }

  get code(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.code, [GlucoseMeetmomentenLOINCCodelijst]);
  }

  get subject(): string | undefined {
    return getReference(this.resource?.subject);
  }

  get focus(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReferences(observationR?.focus);
  }

  get context(): string | undefined {
    const observationR3 = this.resource as r3.Observation;
    return getReference(observationR3.context);
  }

  get encounter(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReference(observationR?.encounter);
  }

  get issued(): string | undefined {
    return this.resource?.issued;
  }

  get performer(): string | undefined {
    return getReferences(this.resource?.performer);
  }

  get value(): string | undefined {
    return getChoiceOfType({
      dateTime: this.resource?.valueDateTime,
      period: this.resource?.valuePeriod,
      time: this.resource?.valueTime,
      string: this.resource?.valueString,
      boolean: this.resource?.valueBoolean,
      ratio: this.resource?.valueRatio,
      sampledData: this.resource?.valueSampledData,
      quantity: this.resource?.valueQuantity,
      range: this.resource?.valueRange,
      codeableConcept: this.resource?.valueCodeableConcept,
    });
  }

  get dataAbsentReason(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.dataAbsentReason);
  }

  get interpretation(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.interpretation, [HARTSLAGREGELMATIGHEID_CODELIJST]);
  }

  get comment(): string | undefined {
    const observationR3 = this.resource as r3.Observation;
    return observationR3?.comment;
  }

  get notes(): AnnotationViewModel[] | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return observationR?.note?.map((n) => new AnnotationViewModel(n, this.fhirVersion));
  }

  get bodySite(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.bodySite);
  }

  get method(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.method);
  }

  get specimen(): string | undefined {
    return getReference(this.resource?.specimen);
  }

  get device(): string | undefined {
    return getReference(this.resource?.device);
  }

  get deviceReference(): string | undefined {
    return this.resource?.device?.reference;
  }

  get referenceRanges(): ObservationReferenceViewModel[] | undefined {
    return this.resource?.referenceRange?.map(
      (referenceRange) => new ObservationReferenceViewModel(referenceRange, this.fhirVersion),
    );
  }

  get hasMember(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReferences(observationR?.hasMember);
  }

  get derivedFrom(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return getReferences(observationR?.derivedFrom);
  }

  get components(): ObservationComponentViewModel[] | undefined {
    return this.resource?.component?.map((component) => new ObservationComponentViewModel(component, this.fhirVersion));
  }

  get timingEvent(): string | undefined {
    const extensionsEventTiming: Extension[] | undefined = new ExtensionsPipe().transform(
      this.resource?.extension,
      StructureDefinition.Hl7.OBSERVATION.eventTiming,
    );
    const extensionsOffset: Extension[] | undefined = new ExtensionsPipe().transform(
      extensionsEventTiming?.flatMap((extension) => extension.extension as r3.Extension[]),
      'offset',
    );
    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectsPipe().transform(
      extensionsOffset,
      'valueCodeableConcept',
    );

    return new CodeableConceptPipe().transform(valueCodeableConcepts);
  }

  get oxygenAdministered(): string | undefined {
    const extensionsAdministeredOxygen: Extension[] | undefined = new ExtensionsPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.OXYGEN.administered,
    );

    const extensionExtraOxygenAdministration: Extension | undefined = new ExtensionPipe().transform(
      extensionsAdministeredOxygen?.flatMap((extension) => extension.extension as r3.Extension[]),
      'extraOxygenAdministration',
    );

    return new YesNoPipe().transform(extensionExtraOxygenAdministration?.valueBoolean);
  }

  get flowRate(): string | undefined {
    const extensionsFlowRate: Extension[] | undefined = new ExtensionsPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.OXYGEN.administered,
    );

    const extensionFlowRate: Extension | undefined = new ExtensionPipe().transform(
      extensionsFlowRate?.flatMap((extension) => extension.extension as r3.Extension[]),
      'flowRate',
    );

    return new QuantityTypePipe().transform(extensionFlowRate?.valueQuantity);
  }

  get inspired(): string | undefined {
    const extensionsInspiredValue: Extension[] | undefined = new ExtensionsPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.OXYGEN.administered,
    );
    const extensionFiO2: Extension | undefined = new ExtensionPipe().transform(
      extensionsInspiredValue?.flatMap((extension) => extension.extension as r3.Extension[]),
      'fiO2',
    );

    return new QuantityTypePipe().transform(extensionFiO2?.valueQuantity);
  }

  get bloodPressure(): string | undefined {
    return (
      this.resource &&
      new QuantitiesTypePipe().transform([
        ObservationViewModel.getValueQuantity(this.resource, [{ system: CodeSystems.LOINC, code: LoincCodes.BP_SYS }]),
        ObservationViewModel.getValueQuantity(this.resource, [{ system: CodeSystems.LOINC, code: LoincCodes.BP_DIAS }]),
      ])
    );
  }

  get profileType(): ProfileType {
    if (
      this.resource?.category?.some((category) =>
        category?.coding?.some(
          (coding) =>
            coding && coding.system === CodeSystems.SNOMED && ['118228005', '384821006'].includes(coding.code ?? ''),
        ),
      )
    ) {
      return ProfileType.functionalOrMentalStatus;
    }

    if (
      this.resource?.meta?.profile?.some(
        (profile) => profile === StructureDefinition.Nictiz.OBSERVATION.gpLaboratoryResult,
      )
    ) {
      return ProfileType.laboratory;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) => coding && coding.system === CodeSystems.SNOMED && ['365508006'].includes(coding.code ?? ''),
      )
    ) {
      return ProfileType.livingSituation;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) => coding && coding.system === CodeSystems.SNOMED && ['228366006'].includes(coding.code ?? ''),
      )
    ) {
      return ProfileType.drugUse;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) => coding && coding.system === CodeSystems.SNOMED && ['365980008'].includes(coding.code ?? ''),
      )
    ) {
      return ProfileType.tobaccoUse;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) => coding && coding.system === CodeSystems.SNOMED && ['228273003'].includes(coding.code ?? ''),
      )
    ) {
      return ProfileType.alcoholUse;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding &&
          ((coding.system === CodeSystems.LOINC && [LoincCodes.BODY_WEIGHT].includes(coding.code ?? '')) ||
            (coding.system === CodeSystems.SNOMED && [SnomedCodes.BODY_WEIGHT].includes(coding.code ?? ''))),
      )
    ) {
      return ProfileType.bodyWeight;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding && coding.system === CodeSystems.LOINC && [LoincCodes.BODY_HEIGHT].includes(coding.code ?? ''),
      )
    ) {
      return ProfileType.bodyHeight;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding &&
          ((coding.system === CodeSystems.LOINC &&
            [
              LoincCodes.GLUSOCE,
              LoincCodes.GLUCOSE_14770_2,
              LoincCodes.GLUCOSE_14743_9,
              LoincCodes.GLUCOSE_14760_3,
            ].includes(coding.code ?? '')) ||
            (coding.system === CodeSystems.NHG_45 &&
              [
                Nhg45.GLUCOSE_DAG_CURVE_GDC,
                Nhg45.GLUCOSE_NIET_NUCHTER_DRAAGBARE_METER,
                Nhg45.GLUCOSE_NUCHTER_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_GDC_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_2U_NA_DINER_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_2U_NA_LUNCH_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_2U_NA_ONTBIJT_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_VOOR_DE_LUNCH_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_VOOR_DE_NACHT_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_VOOR_HET_DINER_DRAAGBARE_METER,
                Nhg45.GLUCOSE_NIET_NUCHTER_VENEUS,
              ].includes(coding.code ?? ''))),
      )
    ) {
      return ProfileType.bloodGlucose;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding &&
          coding.system === CodeSystems.LOINC &&
          [LoincCodes.O2_SATURATION, LoincCodes.O2_SATURATION_BY_PULSE].includes(coding.code ?? ''),
      )
    ) {
      return ProfileType.O2Saturation;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding &&
          ((coding.system === CodeSystems.LOINC &&
            [LoincCodes.BP_PNL_W_ALL_OPTIONAL, LoincCodes.BP_SYS, LoincCodes.BP_DIAS].includes(coding.code ?? '')) ||
            (coding.system === CodeSystems.NHG_45 &&
              [Nhg45.SYSTOLISCHE_BLOEDDRUK, Nhg45.DIASTOLISCHE_BLOEDDRUK].includes(coding.code ?? '')) ||
            (coding.system === CodeSystems.SNOMED && [SnomedCodes.BLOOD_PRESSURE].includes(coding.code ?? ''))),
      )
    ) {
      return ProfileType.bloodPressure;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding &&
          ((coding.system === CodeSystems.SNOMED && [SnomedCodes.RESPIRATORY].includes(coding.code ?? '')) ||
            (coding.system === CodeSystems.LOINC && [LoincCodes.RESPIRATORY_RATE].includes(coding.code ?? ''))),
      )
    ) {
      return ProfileType.respiration;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding && coding.system === CodeSystems.LOINC && [LoincCodes.BODY_TEMPERATURE].includes(coding.code ?? ''),
      )
    ) {
      return ProfileType.bodyTemperature;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding &&
          coding.system === CodeSystems.LOINC &&
          [LoincCodes.HEART_RATE, LoincCodes.PULSE_RATE].includes(coding.code ?? ''),
      )
    ) {
      return ProfileType.heartAndPulseRate;
    }

    if (
      this.resource?.code?.coding?.some(
        (coding) =>
          coding &&
          ((coding.system === CodeSystems.LOINC &&
            [
              LoincCodes.GLUSOCE,
              LoincCodes.GLUCOSE_14770_2,
              LoincCodes.GLUCOSE_14743_9,
              LoincCodes.GLUCOSE_14760_3,
            ].includes(coding.code ?? '')) ||
            (coding.system === CodeSystems.NHG_45 &&
              [
                Nhg45.GLUCOSE_DAG_CURVE_GDC,
                Nhg45.GLUCOSE_NIET_NUCHTER_DRAAGBARE_METER,
                Nhg45.GLUCOSE_NUCHTER_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_GDC_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_2U_NA_DINER_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_2U_NA_LUNCH_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_2U_NA_ONTBIJT_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_VOOR_DE_LUNCH_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_VOOR_DE_NACHT_DRAAGBARE_METER,
                Nhg45.GLUCOSE_DAG_CURVE_VOOR_HET_DINER_DRAAGBARE_METER,
                Nhg45.GLUCOSE_NIET_NUCHTER_VENEUS,
              ].includes(coding.code ?? ''))),
      )
    ) {
      return ProfileType.bloodGlucose;
    }

    return ProfileType.default;
  }

  private get effectiveTiming(): Timing | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return observationR?.effectiveTiming;
  }

  private get effectiveInstant(): string | undefined {
    const observationR = this.resource as r4.Observation | r4b.Observation | r5.Observation;
    return observationR?.effectiveInstant;
  }

  public static getValueQuantity(observation: Observation, codings: Coding[]): Quantity | undefined {
    let value: Quantity | undefined;

    if (this.hasValidCoding(observation.code, codings) && observation.valueQuantity) {
      value = observation.valueQuantity;
    } else {
      // Otherwise check if one of the components has the right coding
      for (const component of observation.component || []) {
        if (this.hasValidCoding(component.code, codings) && component.valueQuantity) {
          value = component.valueQuantity;
          break;
        }
      }
    }

    return value;
  }

  private static hasValidCoding(codeableConcept?: CodeableConcept, accepted?: Coding[]): boolean | undefined {
    if (!codeableConcept || !accepted) {
      return false;
    }

    const codings: Coding[] | undefined = codeableConcept.coding;
    return codings?.some((coding: Coding) =>
      accepted?.some((prop) => coding.system === prop.system && coding.code === prop.code),
    );
  }
}

export enum ProfileType {
  default = 0,
  functionalOrMentalStatus = 1,
  laboratory = 2,
  livingSituation = 3,
  drugUse = 4,
  tobaccoUse = 5,
  alcoholUse = 6,
  bodyWeight = 7,
  bodyHeight = 8,
  bloodGlucose = 9,
  O2Saturation = 10,
  bloodPressure = 11,
  respiration = 12,
  bodyTemperature = 13,
  heartAndPulseRate = 14,
}
