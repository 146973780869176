import { formatLocaleDate } from '@globals';
import { getReference } from '@hl7fhir/foundation';
import { AgeTypePipe } from '../age';
import { YesNoPipe } from '../boolean';
import { CodeableConceptPipe } from '../codeable-concept';
import { PeriodTypePipe } from '../period';
import { QuantityTypePipe } from '../quantity';
import { RangePipe } from '../range';
import { RatioPipe } from '../ratio';
import { ChoiceOfType } from './choice-of-type.model';

export function getChoiceOfType(choiceOfType: ChoiceOfType): string | undefined {
  if (choiceOfType.dateTime) {
    return formatLocaleDate(choiceOfType.dateTime);
  } else if (choiceOfType.period) {
    return new PeriodTypePipe().transform(choiceOfType.period);
  } else if (choiceOfType.timing) {
    // return choiceOfType.timing;
  } else if (choiceOfType.instant) {
    return formatLocaleDate(choiceOfType.instant);
  } else if (choiceOfType.time) {
    return formatLocaleDate(choiceOfType.time);
  } else if (choiceOfType.codeableConcept) {
    return new CodeableConceptPipe().transform(choiceOfType.codeableConcept);
  } else if (choiceOfType.reference) {
    return getReference(choiceOfType.reference);
  } else if (choiceOfType.identifier) {
    // return choiceOfType.identifier;
  } else if (choiceOfType.attachment) {
    // return choiceOfType.attachment;
  } else if (choiceOfType.sampledData) {
    // return choiceOfType.sampledData;
  } else if (choiceOfType.ratio) {
    return new RatioPipe().transform(choiceOfType.ratio);
  } else if (choiceOfType.integer) {
    return choiceOfType.integer.toString();
  } else if (choiceOfType.boolean) {
    return new YesNoPipe().transform(choiceOfType.boolean);
  } else if (choiceOfType.quantity) {
    return new QuantityTypePipe().transform(choiceOfType.quantity);
  } else if (choiceOfType.range) {
    return new RangePipe().transform(choiceOfType.range);
  } else if (choiceOfType.age) {
    return new AgeTypePipe().transform(choiceOfType.age);
  } else if (choiceOfType.string) {
    return choiceOfType.string;
  }

  return undefined;
}

export function getSourceChoiceOfType(choiceOfType: ChoiceOfType): string | undefined {
  if (choiceOfType.dateTime) {
    return choiceOfType.dateTime;
  } else if (choiceOfType.period) {
    return new PeriodTypePipe().transform(choiceOfType.period);
  } else if (choiceOfType.timing) {
    // return choiceOfType.timing;
  } else if (choiceOfType.instant) {
    return choiceOfType.instant;
  } else if (choiceOfType.time) {
    return choiceOfType.time;
  } else if (choiceOfType.codeableConcept) {
    return new CodeableConceptPipe().transform(choiceOfType.codeableConcept);
  } else if (choiceOfType.reference) {
    return getReference(choiceOfType.reference);
  } else if (choiceOfType.identifier) {
    // return choiceOfType.identifier;
  } else if (choiceOfType.attachment) {
    // return choiceOfType.attachment;
  } else if (choiceOfType.sampledData) {
    // return choiceOfType.sampledData;
  } else if (choiceOfType.ratio) {
    return new RatioPipe().transform(choiceOfType.ratio);
  } else if (choiceOfType.integer) {
    return choiceOfType.integer.toString();
  } else if (choiceOfType.boolean) {
    return new YesNoPipe().transform(choiceOfType.boolean);
  } else if (choiceOfType.quantity) {
    return new QuantityTypePipe().transform(choiceOfType.quantity);
  } else if (choiceOfType.range) {
    return new RangePipe().transform(choiceOfType.range);
  } else if (choiceOfType.age) {
    return new AgeTypePipe().transform(choiceOfType.age);
  } else if (choiceOfType.string) {
    return choiceOfType.string;
  }

  return undefined;
}
