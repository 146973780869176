import { DocumentReferenceContext } from '@hl7fhir';
import { CodeableConceptPipe, PeriodTypePipe } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { DocumentReferenceContextRelatedViewModel } from './document-reference-context-related.viewmodel';

export class DocumentReferenceContextViewModel extends BackboneElementViewModel<DocumentReferenceContext> {
  get encounter(): string | undefined {
    if (this.isR3()) {
      const context = this.element as r3.DocumentReferenceContext;
      return context?.encounter && getReference(context.encounter);
    }
    const context = this.element as r4.DocumentReferenceContext | r4b.DocumentReferenceContext;
    return context?.encounter && getReferences(context.encounter);
  }

  get event(): string | undefined {
    return this.element?.event && new CodeableConceptPipe().transform(this.element.event);
  }

  get facilityType(): string | undefined {
    return this.element?.facilityType && new CodeableConceptPipe().transform(this.element.facilityType);
  }

  get period(): string | undefined {
    return this.element?.period && new PeriodTypePipe().transform(this.element.period);
  }

  get practiceSetting(): string | undefined {
    return this.element?.practiceSetting && new CodeableConceptPipe().transform(this.element.practiceSetting);
  }

  get related(): DocumentReferenceContextRelatedViewModel[] | undefined {
    if (this.isR3()) {
      const context = this.element as r3.DocumentReferenceContext;
      return context?.related?.map(
        (related) => new DocumentReferenceContextRelatedViewModel(related, this.fhirVersion),
      );
    }
    return undefined;
  }

  get sourcePatientInfo(): string | undefined {
    return this.element?.sourcePatientInfo && getReference(this.element.sourcePatientInfo);
  }
}
