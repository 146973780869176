import { ResourceTypes } from '@globals/constants';
import { Reference } from '@hl7fhir';
import { Dictionary } from '@ngrx/entity';

export function getIdFromRelativeReference(type: ResourceTypes, reference: Reference | undefined): string | undefined {
  if (!reference?.reference) {
    return undefined;
  }

  return reference.reference.split(`${type}/`)[1];
}

export function getIdsFromRelativeReferences(
  type: ResourceTypes,
  references: Reference[] | undefined,
): string[] | undefined {
  if (!references) {
    return undefined;
  }

  const ids: string[] = [];

  for (const element of references) {
    if (element?.reference) {
      ids.push(element.reference.split(`${type}/`)[1]);
    }
  }

  return ids;
}

export function getResource<Type>(
  type: ResourceTypes,
  reference: Reference | undefined,
  dictionary: Dictionary<Type>,
): Type | undefined {
  if (!reference) {
    return undefined;
  }

  const resourceId: string | undefined = getIdFromRelativeReference(type, reference);

  let resource: Type | undefined;

  if (resourceId) {
    resource = dictionary[resourceId];
  }

  return resource;
}

export function getResources<Type>(
  type: ResourceTypes,
  references: Reference[] | undefined,
  dictionary: Dictionary<Type>,
): Type[] | undefined {
  if (!references) {
    return undefined;
  }

  const resourceIds = getIdsFromRelativeReferences(type, references);

  const resources: Type[] = [];

  if (resourceIds) {
    resourceIds.forEach((id: string | undefined) => {
      if (!id) {
        return;
      }

      const resource = dictionary[id];

      if (resource) {
        resources.push(resource);
      }
    });
  }

  return resources;
}
