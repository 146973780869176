import { formatLocaleDate } from '@globals';
import { ImmunizationRecommendationRecommendation } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { getReferences } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { ImmunizationRecommendationRecommendationDateCriterionViewModel } from './immunization-recommendation-recommendation-date-criterion.viewmodel';
import { ImmunizationRecommendationRecommendationProtocolViewModel } from './immunization-recommendation-recommendation-protocol.viewmodel';

export class ImmunizationRecommendationRecommendationViewModel extends BackboneElementViewModel<ImmunizationRecommendationRecommendation> {
  get date(): string | undefined {
    const recommend = this.element as r3.ImmunizationRecommendationRecommendation;

    return recommend.date && formatLocaleDate(recommend.date);
  }

  get vaccineCode(): string | undefined {
    return this.element?.vaccineCode && new CodeableConceptPipe().transform(this.element?.vaccineCode);
  }

  get targetDisease(): string | undefined {
    return this.element?.targetDisease && new CodeableConceptPipe().transform(this.element?.targetDisease);
  }

  //R4, R4b, R5 only
  get description(): string | undefined {
    const recommend = this.element as
      | r4.ImmunizationRecommendationRecommendation
      | r4b.ImmunizationRecommendationRecommendation
      | r5.ImmunizationRecommendationRecommendation;

    return recommend.description;
  }

  //R4, R4b, R5 only
  get series(): string | undefined {
    const recommend = this.element as
      | r4.ImmunizationRecommendationRecommendation
      | r4b.ImmunizationRecommendationRecommendation
      | r5.ImmunizationRecommendationRecommendation;

    return recommend.series;
  }

  get doseNumber(): string | undefined {
    const recommendR3R5 = this.element as
      | r3.ImmunizationRecommendationRecommendation
      | r5.ImmunizationRecommendationRecommendation;
    const recommendR4R4b = this.element as
      | r4.ImmunizationRecommendationRecommendation
      | r4b.ImmunizationRecommendationRecommendation;

    return recommendR3R5.doseNumber?.toString() ?? recommendR4R4b.doseNumberString;
  }

  //R4, R4b, R5 only
  get seriesDoses(): string | undefined {
    const recommendR5 = this.element as r5.ImmunizationRecommendationRecommendation;
    const recommend = this.element as
      | r4.ImmunizationRecommendationRecommendation
      | r4b.ImmunizationRecommendationRecommendation;

    return recommendR5.seriesDoses?.toString() ?? recommend.seriesDosesString;
  }

  get forecastStatus(): string | undefined {
    return this.element?.forecastStatus && new CodeableConceptPipe().transform(this.element.forecastStatus);
  }

  //R4, R4b, R5 only
  get forecastReason(): string | undefined {
    const recommend = this.element as
      | r4.ImmunizationRecommendationRecommendation
      | r4b.ImmunizationRecommendationRecommendation
      | r5.ImmunizationRecommendationRecommendation;

    return recommend.forecastReason && new CodeableConceptPipe().transform(recommend.forecastReason);
  }

  get supportingImmunization(): string | undefined {
    return this.element?.supportingImmunization && getReferences(this.element.supportingImmunization);
  }

  get supportingPatientInformation(): string | undefined {
    return this.element?.supportingPatientInformation && getReferences(this.element.supportingPatientInformation);
  }

  get dateCriterion(): ImmunizationRecommendationRecommendationDateCriterionViewModel[] | undefined {
    return (
      this.element?.dateCriterion &&
      this.element.dateCriterion?.map(
        (item) => new ImmunizationRecommendationRecommendationDateCriterionViewModel(item, this.fhirVersion),
      )
    );
  }

  //R3 only
  get protocol(): ImmunizationRecommendationRecommendationProtocolViewModel | undefined {
    const recommend = this.element as r3.ImmunizationRecommendationRecommendation;
    return (
      recommend?.protocol &&
      new ImmunizationRecommendationRecommendationProtocolViewModel(recommend.protocol, this.fhirVersion)
    );
  }
}
