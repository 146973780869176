import { createSelector } from '@ngrx/store';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export const selectAddingFiles = createSelector(selectDigiMeState, (state: DigiMeState) => state?.addingFiles);

export const selectAnyFileProcessedFailed = createSelector(selectAddingFiles, (files) => {
  return files.length > 0 && files.some((file) => file.addingState === 'Error');
});

export const selectAllFilesProcessedSuccessfully = createSelector(selectAddingFiles, (files) => {
  return files.length > 0 && files.every((file) => file.addingState === 'Added');
});
