import { Flag } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectFlagId(flag: Flag): string {
  if (!flag.id) throw new Error('The flag is not created yet.');

  return flag.id;
}

export type State = EntityState<Flag>;

export const adapter: EntityAdapter<Flag> = createEntityAdapter<Flag>({
  selectId: selectFlagId,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { flags }) => {
    return adapter.upsertMany(flags, state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectFlagEntities = selectEntities;

export const selectAllFlags = selectAll;
