import { Pipe, PipeTransform } from '@angular/core';
import { CodeableConcept, Coding } from '@hl7fhir';

@Pipe({
  name: 'codeableConceptFilter',
})
export class CodeableConceptFilterPipe implements PipeTransform {
  transform(codeableConcepts: CodeableConcept[] | undefined, ...filters: Coding[]): CodeableConcept[] | undefined {
    if (!codeableConcepts) return undefined;

    ///filter by system and or code if they are set in the filter
    return codeableConcepts.filter((codeableConcept) =>
      codeableConcept?.coding?.some(
        (coding) =>
          !filters ||
          filters.some(
            (filter) =>
              (!filter.system || filter.system === coding.system) && (!filter.code || filter.code === coding.code),
          ),
      ),
    );
  }
}
