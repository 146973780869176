import { Practitioner } from '@hl7fhir';
import { PractitionerViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromPractitioner from './practitioner-reducer';

export const selectPractitionerState = createFeatureSelector<fromPractitioner.State>(features.FHIR.practitioners);

export const selectPractitionerEntities = createSelector(
  selectPractitionerState,
  fromPractitioner.selectPractitionerEntities,
);

export const selectAllPractitioners = createSelector(selectPractitionerState, fromPractitioner.selectAllPractitioners);

export const selectPractitionerById = createSelector(
  selectRouteParams,
  selectPractitionerEntities,
  ({ id }, practitioners: Dictionary<Practitioner>) =>
    id && (practitioners[id] ?? practitioners[id.toLowerCase()] ?? practitioners[id.toUpperCase()]),
);

export const selectAllPractitionersViewModels = createSelector(selectAllPractitioners, (practitioners) =>
  practitioners.map((practitioner) => new PractitionerViewModel(practitioner)),
);

export const selectPractitionerViewModelById = createSelector(
  selectPractitionerById,
  (practitioner) => practitioner && new PractitionerViewModel(practitioner),
);
