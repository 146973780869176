import { Pipe, PipeTransform } from '@angular/core';
import { Coding } from '@hl7fhir';

@Pipe({
  name: 'codingFilter',
})
export class CodingFilterPipe implements PipeTransform {
  transform(codings: Coding[] | undefined, ...filters: Coding[]): Coding[] | undefined {
    if (!codings) return undefined;

    // filter by system and or code if they are set in the filter
    return codings.filter(
      (coding) =>
        !filters ||
        filters.some(
          (filter) =>
            (!filter.system || filter.system === coding.system) && (!filter.code || filter.code === coding.code),
        ),
    );
  }
}
