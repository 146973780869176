import { Pipe, PipeTransform } from '@angular/core';
import { ValueSetFlattened } from '@hl7fhir/value-sets';
import { CodingsPipe } from '../coding';

@Pipe({
  name: 'code',
})
export class CodePipe implements PipeTransform {
  readonly codingsPipe = new CodingsPipe();

  transform(
    code: string | undefined,
    system: string | undefined,
    preferredValueSets?: ValueSetFlattened[],
  ): string | undefined {
    if (!system || !code) {
      return undefined;
    }

    return this.codingsPipe.transform({ system: system, code: code }, preferredValueSets);
  }
}
