import { createSelector } from '@ngrx/store';
import { ErrorState, LoadingState } from '@store';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export const selectIsPortabilityReportLoading = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.portabilityReport.callState === LoadingState.loading,
);

export const selectPortabilityReport = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.portabilityReport.data,
);

export const selectPortabilityReportError = createSelector(selectDigiMeState, (state: DigiMeState) => {
  const errorState = state.portabilityReport.callState as ErrorState;

  if (errorState.error && errorState.error.status === 403) {
    return undefined;
  }
  return errorState?.error;
});
