import { Practitioner } from '@hl7fhir';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_UI_DATA_ACTIONS } from '../../digi.me/digi-me.actions';
import { DIGI_ME_FHIR_ACTIONS } from '../fhir.actions';

export function selectPractitionerId(practitioner: Practitioner): string {
  // The only time that a resource does not have an id is when it is being submitted to the server using a create operation.
  // https://www.hl7.org/fhir/resource-definitions.html#Resource.id
  if (!practitioner.id) throw new Error('The practitioner is not created yet.');
  return practitioner.id;
}

export function sortById(a: Practitioner, b: Practitioner): number {
  return selectPractitionerId(a).localeCompare(selectPractitionerId(b));
}

export type State = EntityState<Practitioner>;

export const adapter: EntityAdapter<Practitioner> = createEntityAdapter<Practitioner>({
  selectId: selectPractitionerId,
  sortComparer: sortById,
});

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(DIGI_ME_FHIR_ACTIONS.fhirLoaded, (state, { practitioners }) => {
    // TODO Fhir multiversion
    return adapter.upsertMany(practitioners as Practitioner[], state);
  }),
  on(CLEAR_UI_DATA_ACTIONS.clearDataRequested, (state) => adapter.removeAll(state)),
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectPractitionerEntities = selectEntities;

export const selectAllPractitioners = selectAll;
