import { formatLocaleDate } from '@globals';
import { Practitioner } from '@hl7fhir';
import {
  AddressViewModel,
  CodeableConceptPipe,
  ContactPointViewModel,
  FullNamePipe,
  HumanNameViewModel,
  IdentifierViewModel,
} from '@hl7fhir/data-types';
import { AdministrativeGenderPipe } from '@hl7fhir/value-sets';
import { ResourceViewModel } from '@hl7fhir/viewmodels';
import { PractitionerQualificationViewModel } from './practitioner-qualification.viewmodel';

export class PractitionerViewModel extends ResourceViewModel<Practitioner> {
  get identifier(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get name(): string | undefined {
    return new FullNamePipe().transform(this.resource?.name);
  }

  get humanNames(): HumanNameViewModel[] | undefined {
    return this.resource?.name?.map((name) => new HumanNameViewModel(name, this.fhirVersion));
  }

  get telecoms(): ContactPointViewModel[] | undefined {
    return this.resource?.telecom?.map((telecom) => new ContactPointViewModel(telecom, this.fhirVersion));
  }

  get addresses(): AddressViewModel[] | undefined {
    return this.resource?.address?.map((address) => new AddressViewModel(address, this.fhirVersion));
  }

  get gender(): string | undefined {
    return new AdministrativeGenderPipe().transform(this.resource?.gender);
  }

  get birthDate(): string | undefined {
    return formatLocaleDate(this.resource?.birthDate);
  }

  get communication(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.communication);
  }

  get qualification(): PractitionerQualificationViewModel[] | undefined {
    return this.resource?.qualification?.map(
      (qualification) => new PractitionerQualificationViewModel(qualification, this.fhirVersion),
    );
  }
}
